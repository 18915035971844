import React, { FC, useEffect } from 'react'
import { useCustomers } from "../../api/hooks/useCustomers";
import { TD } from "./TableComponents";

export const CustomerChipRelationsRows: FC = () => {
    const { customersRelations, getCustomersRelations } = useCustomers()

    useEffect(() => {
        getCustomersRelations()
    }, [])// eslint-disable-line

    return <>
        {customersRelations.map(relation => <tr key={relation.customerID.toString(10) + 'opn' + relation.opnID.toString(10)}>
            <TD>{relation.customerName}</TD>
            <TD>{relation.opnName}</TD>
            <TD>{relation.comment ? relation.comment : ''}</TD>
        </tr>)}
    </>
}