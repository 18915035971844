export enum ActionType {
    SwitchTheme = "SwitchTheme",
    SwitchLanguage = "SwitchLanguage",
    SetUser = "SetUser",
    SetToken = "SetToken",
    SetLoginLoading = "SetLoginLoading",
    SetApiError = "SetApiError",
    ClearApiError = "ClearApiError",
    ClearUser = "ClearUser",
    ClearToken = "ClearToken",
    AppendForecasts = "AppendForecasts",
    ClearForecasts = "ClearForecasts",
    SetForecastsLoading = "SetForecastsLoading",
    SetCustomers = "SetCustomers",
    SetCustomersLoading = "SetCustomersLoading",
    SetCustomerToRelation = "SetCustomerToRelation",
    SetCommentToRelation = "SetCommentToRelation",
    SetOPNs = "SetOPNs",
    SetOPNsLoading = "SetOPNsLoading",
    SetOPNToRelation = "SetOPNToRelation",
    SetCustomersRelations = "SetCustomersRelations",
    SetCustomersRelationsLoading = "SetCustomersRelationsLoading",
    AddFirstCustomersRelation = "AddFirstCustomersRelation",
    ReplaceCustomer = "ReplaceCustomer",
    SetCreatingCustomerRelationLoading = "SetCreatingCustomerRelationLoading",
    ResetNewCustomerRelation = "ResetNewCustomerRelation",
    SetCustomerRelation = "SetCustomerRelation",
    SetCustomerRelationLoading = "SetCustomerRelationLoading",
    AddForecastFirst = "AddForecastFirst",
    SetAddForecastLoading = "SetAddForecastLoading",
    RemoveForecast = "RemoveForecast",
    SetUpdateForecastLoading = "SetUpdateForecastLoading",
    SetUserLoading = "SetUserLoading",
    SetUserSaving = "SetUserSaving",
    SetAvatarUpdating = "SetAvatarUpdating",
    SetSamplePrelimPlanning = "SetSamplePrelimPlanning",
    SetPrelimPlannings = "SetPrelimPlannings",
    SetPrelimPlanningsLoading = "SetPrelimPlanningsLoading",
    UpdatePrelimPlanning = "UpdatePrelimPlanning",
    SetPrelimPlanningUpdateLoading = "SetPrelimPlanningUpdateLoading",
    SetOPNFullList = "SetOPNFullList",
    SetOPNFullListLoading = "SetOPNFullListLoading",
    SetLinesLoading = "SetLinesLoading",
    SetCategoriesLoading = "SetCategoriesLoading",
    SetFamiliesLoading = "SetFamiliesLoading",
    SetWafersLoading = "SetWafersLoading",
    SetDiesLoading = "SetDiesLoading",
    SetOPNCreateLoading = "SetOPNCreateLoading",
    AddOPNToListFirst = "AddOPNToListFirst",
    SetCustomersAdmin = "SetCustomersAdmin",
    AddCustomerAdminFirst = "AddCustomerAdminFirst",
    UpdateCustomerAdmin = "UpdateCustomerAdmin",
    SetCustomerAddLoading = "SetCustomerAddLoading",
    SetTerritories = "SetTerritories",
    SetBUApplications = "SetBUApplications",
    SetTerritoriesLoading = "SetTerritoriesLoading",
    SetBUApplicationsLoading = "SetBUApplicationsLoading",
    RemoveOPNFromAdmin = "RemoveOPNFromAdmin",
    SetPasswordUpdateLoading = "SetPasswordUpdateLoading"
}