import React, { FC, useEffect, useState } from 'react'
import s from './CustomersTable.module.css'
import { Table } from "../../../ui_components/table/Table";
import { Header } from "../../../ui_components/table/Header";
import { TD, TH } from "../../customers/TableComponents";
import { useSelector } from "react-redux";
import { StoreState } from "../../../api/redux/store";
import { useCustomersManage } from "../../../api/hooks/useCustomersManage";
import { CustomerEditorRow } from "./CustomerEditorRow";
import { CustomerView } from "../../../api/types/customer";

export const CustomersTable: FC = () => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const { customers, getCustomers } = useCustomersManage()
    const [ editedCustomer, setEditedCustomer ] = useState<CustomerView|null>(null)

    useEffect(() => {
        getCustomers()
    }, []) // eslint-disable-line

    return <div className={ s.root }>
        <Table>
            <Header>
                <TH>{ lang.pages.customersManage.table.customerID }</TH>
                <TH>{ lang.pages.customersManage.table.customerName }</TH>
                <TH>{ lang.pages.customersManage.table.BUApplicationName }</TH>
                <TH>{ lang.pages.customersManage.table.territoryName }</TH>
            </Header>
            <tbody>
                <CustomerEditorRow editedCustomer={null}/>
                { customers.map((customer, cIndex) => editedCustomer?.customerID === customer.customerID ? <CustomerEditorRow key={`${customer.customerID}-${cIndex}`} editedCustomer={editedCustomer} onDone={() => setEditedCustomer(null)}/> : <tr
                    key={`${customer.customerID}-${cIndex}`}
                    className={ `${ s.rowArrowCursor } ${ s.noSelect }` }
                    onDoubleClick={() => {
                        console.log('Edit customer')
                        setEditedCustomer(customer)
                    }}
                    onClick={() => {
                        if(editedCustomer && editedCustomer.customerID !== customer.customerID) setEditedCustomer(null)
                    }}
                >
                    <TD>{customer.customerID}</TD>
                    <TD>{customer.customerName}</TD>
                    <TD>{customer.BUApplicationName}</TD>
                    <TD>{customer.territoryName}</TD>
                </tr>) }
            </tbody>
        </Table>
    </div>
}