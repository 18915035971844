import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Button, CircularProgress, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../../api/redux/store";
import s from './InlineLogin.module.css'
import { useUserLogin } from "../../api/hooks/useUserLogin";
import { iLoaders } from "../../api/redux/reducers/loadersReducer";

export const InlineLogin: FC = () => {
    const lang = useSelector ((state: StoreState) => state.lang)
    const [, login] = useUserLogin()

    const loaders : iLoaders = useSelector ((state: StoreState) => state.loaders)

    const [ emailValid, setEmailValid ] = useState<boolean> (false)
    const [ passwordValid, setPasswordValid ] = useState<boolean> (false)

    const [ email, setEmail ] = useState<string> ('')
    const [ password, setPassword ] = useState<string> ('')

    useEffect (() => {
        setEmailValid (Boolean (email.match (/.+@.+\..+/)))
        setPasswordValid (password.length > 0)
    }, [ email, password ])

    return <div className={ s.root }>
        <TextField
            value={ email }
            onChange={ (e: ChangeEvent<HTMLInputElement>) => setEmail (e.target.value) }
            variant={ "outlined" }
            helperText={ !emailValid ? lang.ui.login.emailNotValid : ' ' }
            InputProps={ { classes: { notchedOutline: s.notchedBorder } } }
            FormHelperTextProps={ { classes: { root: s.helperText } } }
            label={ lang.ui.login.emailLabel } type={ "email" } size={ "small" }/>
        <TextField
            value={ password }
            onChange={ (e: ChangeEvent<HTMLInputElement>) => setPassword (e.target.value) }
            variant={ "outlined" }
            helperText={ !passwordValid ? lang.ui.login.passwordNotValid : ' ' }
            InputProps={ { classes: { notchedOutline: s.notchedBorder } } }
            FormHelperTextProps={ { classes: { root: s.helperText } } }
            label={ lang.ui.login.passwordLabel } type={ "password" } size={ "small" }/>
        <Button onClick={() => login(email, password)} endIcon={ loaders.loginLoading && <CircularProgress size={ 14 }/> } variant={ "contained" } disabled={!emailValid || !passwordValid || loaders.loginLoading} classes={{disabled: s.buttonDisabled}}>
            { lang.ui.login.signInBTN }
        </Button>

    </div>
}