import React, { FC, useEffect, useRef, useState } from 'react'
import { ChipCategory, ChipLine } from "../../../../api/types/chipTypes";
import s from "../OPNCreatorDialog.module.css"
import { Autocomplete, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import { useChipManage } from "../../../../api/hooks/useChipManage";
import { useLoaders } from "../../../../api/hooks/useLoaders";
import { usePrevious } from "../../../../api/hooks/usePrevious";
import { ConfirmationDialog } from "../../../../ui_components/ConfirmationDialog";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../api/redux/store";

interface ChipCategoryChooserProps {
    selectedCategory: ChipCategory | null
    onCategorySelected: (category: ChipCategory | null) => void
    selectedLine: ChipLine | null
}

export const ChipCategoryChooser: FC<ChipCategoryChooserProps> = ({
                                                                      selectedCategory,
                                                                      onCategorySelected,
                                                                      selectedLine
                                                                  }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const { chipCategories, getChipCategoriesList, createChipCategory } = useChipManage ()
    const { categoriesLoading } = useLoaders ()
    const [ value, setValue ] = useState<string> ('')

    const prevAmount = usePrevious ({ value, selectedCategory })
    const timer: { current: ReturnType<typeof setTimeout> | null } = useRef (null)

    const [ openedConfirmDialog, setOpenedConfirmDialog ] = useState<boolean>(false)

    useEffect (() => {
        if (selectedLine) getChipCategoriesList (null, selectedLine.lineID)
        setValue('')
    }, [ selectedLine ]) // eslint-disable-line

    useEffect (() => {
        //This effect is needed in order to create a delay and allow the user to type text.
        //If the timer did not finish and the user wrote a new character, then reset the timer
        if (!categoriesLoading && timer.current) clearTimeout (timer.current)
        //If the previous value and the new value of the text field are different
        // (i.e., there have been changes), and also if the list of chips is not loaded at this time,
        // then we start the timer, after which the list will start loading.
        if (!categoriesLoading && prevAmount && value !== prevAmount.value) {
            timer.current = setTimeout (() => {
                if (selectedLine) getChipCategoriesList (value, selectedLine.lineID)
            }, 1000)
        }

        //Reset the timer when the component will unmount
        return () => {
            if (timer.current) clearTimeout (timer.current)
        }
    }, [ value, categoriesLoading, prevAmount?.value, selectedLine ]) // eslint-disable-line

    //If before Category has selected and now it is undefined then load full list of a chips
    useEffect (() => {
        if (prevAmount?.selectedCategory !== null && selectedCategory === null && selectedLine !== null) getChipCategoriesList (null, selectedLine.lineID)
    }, [ selectedCategory, prevAmount, selectedLine ]) // eslint-disable-line

    return <div className={ s.chooserRow }>
        <Autocomplete
            disabled={ selectedLine === null }
            classes={ { root: s.autocomplete } }
            disablePortal
            clearOnBlur={false}
            renderInput={ (params) => <TextField { ...params } label={lang.pages.opnManage.opnCreatorDialog.categoriesChooser.fieldLabel}/> }
            options={ chipCategories }
            onChange={ (event, category: ChipCategory | null) => onCategorySelected (category) }
            value={ selectedCategory}
            getOptionLabel={ (category: ChipCategory) => category.chipCategoryName }
            isOptionEqualToValue={ (category: ChipCategory, value: ChipCategory) => category.lineID === value.lineID }
            size={ 'small' }
            onInputChange={ (event, inputValue) => {
                setValue (inputValue)
                if (selectedCategory) onCategorySelected(null)
            } }
            inputValue={ value }
            multiple={false}
        />
        <IconButton color={'primary'} disabled={value === ""} onClick={() => {
            console.log(value)
            setOpenedConfirmDialog(true)
        }}>
            <AddIcon fontSize={ 'medium' } classes={ { root: s.icon } }/>
        </IconButton>
        { openedConfirmDialog && <ConfirmationDialog
            close={() => setOpenedConfirmDialog(false)}
            message={lang.pages.opnManage.opnCreatorDialog.categoriesChooser.confirmMessage}
            onConfirm={() => {
                if (selectedLine) createChipCategory(value, selectedLine.lineID)
            }}
        />}
    </div>
}