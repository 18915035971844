import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { ChipOPN } from "../../../api/types/chipTypes";
import { Customer } from "../../../api/types/customer";
import { useChips } from "../../../api/hooks/useChips";
import { Autocomplete, TextField } from "@mui/material";
import { usePrevious } from "../../../api/hooks/usePrevious";
import { useLoaders } from "../../../api/hooks/useLoaders";
import s from './AddForecastDialog.module.css'

interface ChipsAutoListProps {
    chip: ChipOPN | null
    customer?: Customer
    onSelect: (chip: ChipOPN | null) => void
}

export const ChipsAutoList: FC<ChipsAutoListProps> = ({ chip, customer, onSelect }) => {
    const { chips, loadOPNs } = useChips ()
    const [ value, setValue ] = useState ('')
    const { OPNsLoading } = useLoaders ()

    useEffect (() => {
        if (customer) {
            onSelect (null)
            loadOPNs (value, customer.customerID)
        }
    }, [ customer ]) // eslint-disable-line

    const prevAmount = usePrevious ({ value, chip })

    const timer: { current: ReturnType<typeof setTimeout> | null } = useRef (null)

    useEffect (() => {
        //This effect is needed in order to create a delay and allow the user to type text.
        //If the timer did not finish and the user wrote a new character, then reset the timer
        if (!OPNsLoading && timer.current) clearTimeout (timer.current)
        //If the previous value and the new value of the text field are different
        // (i.e., there have been changes), and also if the list of chips is not loaded at this time,
        // then we start the timer, after which the list will start loading.
        if (customer && !OPNsLoading && prevAmount && value !== prevAmount.value) {
            timer.current = setTimeout (() => loadOPNs (value, customer.customerID), 1000)
        }

        //Reset the timer when the component will unmount
        return () => {
            if (timer.current) clearTimeout (timer.current)
        }
    }, [ value, OPNsLoading, prevAmount?.value, customer ]) // eslint-disable-line


    //If before chip was selected and now it is undefined then load full list of a chips
    useEffect (() => {
        if (customer && prevAmount?.chip !== null && chip === null) loadOPNs ('', customer.customerID)
    }, [ chip, prevAmount, customer ]) // eslint-disable-line

    return <Autocomplete
        classes={ { root: s.autocomplete } }
        value={ chip }
        inputValue={ value ? value : '' }
        isOptionEqualToValue={ (option, value) => option.opnID === value?.opnID }
        options={ chips }
        onChange={ (_event: SyntheticEvent, value: ChipOPN | null) => onSelect (value) }
        onInputChange={ (e, v) => setValue (v) }
        getOptionLabel={ (option) => option.opnName }
        size={ 'small' }
        disabled={ !Boolean (customer) }
        renderInput={ (params) => <TextField { ...params } label="Add part number"/> }
    />
}