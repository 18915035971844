import React, { FC, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField } from "@mui/material";
import Settings from "../../../api/settings";
import { useSelector } from "react-redux";
import { StoreState } from "../../../api/redux/store";
import s from "./OPNCreatorDialog.module.css"
import { ChipLinesChooser } from "./opnParametersFields/ChipLinesChooser";
import { ChipCategory, ChipDIE, ChipFamily, ChipLine, Wafer } from "../../../api/types/chipTypes";
import { ChipCategoryChooser } from "./opnParametersFields/ChipCategoryChooser";
import { ChipFamilyChooser } from "./opnParametersFields/ChipFamilyChooser";
import { WaferChooser } from "./opnParametersFields/WaferChooser";
import { DieChooser } from "./opnParametersFields/DieChooser";
import { useChipManage } from "../../../api/hooks/useChipManage";
import { useLoaders } from "../../../api/hooks/useLoaders";

interface OPNCreatorDialogProps {
    close: () => void
}

export const OPNCreatorDialog: FC<OPNCreatorDialogProps> = ({ close }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const [ opened, setOpened ] = useState (true)

    const [ line, setLine ] = useState<ChipLine | null> (null)
    const [ category, setCategory ] = useState<ChipCategory | null> (null)
    const [ family, setFamily ] = useState<ChipFamily | null> (null)
    const [ wafer, setWafer ] = useState<Wafer | null> (null)
    const [ die, setDIE ] = useState<ChipDIE | null> (null)

    const [ opnName, setOPNName ] = useState<string> ('')

    const { createOPN } = useChipManage ()

    const { OPNCreateLoading } = useLoaders()

    const pendingClose = () => {
        setOpened (false);
        setTimeout (() => close (), 500)
    }

    return <Dialog
        maxWidth={ 'xs' }
        fullWidth
        open={ opened }
        transitionDuration={ Settings.FADE_DURATION }
        onClose={ pendingClose }
    >
        <DialogTitle>
            { lang.pages.opnManage.opnCreatorDialog.title.toUpperCase () }
        </DialogTitle>
        <DialogContent className={ s.content }>
            <ChipLinesChooser selectedLine={ line } onLineSelected={ newLine => {
                setLine (newLine)
                setCategory (null)
                setFamily (null)
            } }/>
            <ChipCategoryChooser selectedCategory={ category } onCategorySelected={ newCategory => {
                setCategory (newCategory)
                setFamily (null)
            } } selectedLine={ line }/>
            <ChipFamilyChooser selectedFamily={ family } onFamilySelected={ setFamily } selectedCategory={ category }/>
            <WaferChooser selectedWafer={ wafer } onWaferSelected={ newWafer => {
                setWafer (newWafer)
                setDIE (null)
            } }/>
            <DieChooser selectedDie={ die } onDieSelected={ setDIE } selectedWafer={ wafer }/>
            <TextField
                onChange={ (event) => setOPNName (event.target.value) }
                className={ s.autocomplete }
                size={ 'small' }
                label={ lang.pages.opnManage.opnCreatorDialog.opnLabel }
            />
            { OPNCreateLoading && <LinearProgress/> }
        </DialogContent>
        <DialogActions>
            <Button onClick={ pendingClose } color={ 'secondary' }>
                { lang.ui.commons.cancelBTN }
            </Button>
            <Button color={ 'primary' } disabled={ family === null || die === null || opnName === '' || OPNCreateLoading } onClick={ () => {
                if (family && die) createOPN (opnName, die.dieID, family.familyID, () => {
                    pendingClose ()
                })
            } }>
                { lang.ui.commons.addBTN }
            </Button>
        </DialogActions>
    </Dialog>
}