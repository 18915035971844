import React, { FC, useEffect, useState } from 'react'
import { Wafer } from "../../../../api/types/chipTypes";
import { useChipManage } from "../../../../api/hooks/useChipManage";
import s from "../OPNCreatorDialog.module.css";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import { ConfirmationDialog } from "../../../../ui_components/ConfirmationDialog";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../api/redux/store";

interface WaferChooserProps {
    selectedWafer: Wafer | null
    onWaferSelected: (wafer: Wafer | null) => void
}

export const WaferChooser: FC<WaferChooserProps> = ({ selectedWafer, onWaferSelected }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const { wafers, getWafersList, createWafer } = useChipManage ()
    const [ value, setValue ] = useState<string> ('')

    const [ openedConfirmDialog, setOpenedConfirmDialog ] = useState<boolean>(false)

    useEffect (() => {
        getWafersList ()
    }, []) // eslint-disable-line

    return <div className={ s.chooserRow }>
        <Autocomplete
            // disabled={ wafersLoading }
            classes={ { root: s.autocomplete } }
            disablePortal
            clearOnBlur={false}
            renderInput={ (params) => <TextField { ...params } label={lang.pages.opnManage.opnCreatorDialog.waferChooser.fieldLabel}/> }
            options={ wafers }
            onChange={ (event, wafer: Wafer | null) => onWaferSelected (wafer) }
            value={ selectedWafer }
            getOptionLabel={ (wafer: Wafer) => wafer.waferName }
            isOptionEqualToValue={ (wafer: Wafer, value: Wafer) => wafer.waferID === value.waferID }
            size={ 'small' }
            onInputChange={ (event, inputValue) => {
                setValue (inputValue)
                if (selectedWafer) onWaferSelected(null)
            } }
        />
        <IconButton color={'primary'} disabled={value === ""} onClick={() => {setOpenedConfirmDialog(true)}}>
            <AddIcon fontSize={ 'medium' } classes={ { root: s.icon } }/>
        </IconButton>
        { openedConfirmDialog && <ConfirmationDialog
            close={() => setOpenedConfirmDialog(false)}
            message={lang.pages.opnManage.opnCreatorDialog.waferChooser.confirmMessage}
            onConfirm={() => {
                createWafer(value)
            }}
        />}
    </div>
}