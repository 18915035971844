import {LangName, LangPack} from "./types";

export const en: LangPack = {
    lang: LangName.EN,
    routes: {
        dashboard: {
            title: 'Dashboard'
        },
        customers: {
            title: 'Customers',
            shortMenuName: 'Customers'
        },
        forecast: {
            title: 'Forecast',
            shortMenuName: 'Forecast'
        },
        prelim_allocation: {
            title: 'Preliminary allocation',
            shortMenuName: 'Prelim. allocation'
        },
        final_allocation: {
            title: 'Final allocation',
            shortMenuName: 'Final allocation'
        },
        prelim_wafer_planning: {
            title: 'Preliminary wafer planning',
            shortMenuName: 'Prelim. wafer planning'
        },
        wafer_planning: {
            title: 'Wafer planning',
            shortMenuName: 'Wafer planning'
        },
        opn_manage: {
            title: 'OPN management',
            shortMenuName: 'OPN management'
        },
        customers_manage: {
            title: 'Customers management',
            shortMenuName: 'Customers'
        }
    },
    ui: {
        login: {
            emailLabel: 'Email',
            passwordLabel: 'Password',
            signInBTN: 'Sign in',
            emailNotValid: 'Email is not valid or empty',
            passwordNotValid: 'Password is not valid or empty'
        },
        errorDialog: {
            errorPad: 'Error ',
        },
        commons: {
            okBTN: 'Ok',
            yesBTN: 'Yes',
            noBTN: 'No',
            cancelBTN: 'Cancel',
            addBTN: 'Add',
            applyBTN: 'Apply',
            applyAndClose: 'Apply & Close'
        },
        userMenu: {
            editProfile: 'Edit profile',
            logOut: 'Log out...'
        },
        profileEditorDialog: {
            title: 'Edit profile data',
            firstnameLBL: 'Firstname',
            lastnameLBL: 'Lastname',
            changePasswordLBL: 'Change password',
            retypePasswordLBL: 'Retype password',
            passwordEmptySecondField: 'The field must not be empty',
            passwordsNotEqual: 'Passwords not equal'
        },
        logOutDialog: {
            title: 'Wait...',
            content: 'Are you sure you want to log out?'
        }
    },
    pages: {
        forecast: {
            table: {
                customerName: 'Customer name',
                partNumber: 'Part number',
                accountManager: 'Account manager',
                territory: 'Territory'
            },
            addForecastDialog: {
                title: 'Add Forecast',
            },
            editForecastDialog: {
                title: 'Edit forecast',
                quarter: {
                    headerLabel: 'Quarter',
                    quantityLabel: 'quantity',
                    distributionCostLabel: 'distr. cost'
                }
            }
        },
        prelimAllocation: {
            editPrelimAllocationDialog: {
                title: 'Edit prelim. Allocation',
                quarter: {
                    headerLabel: 'Quarter',
                    quantityLabel: 'quantity (k)',
                    minRevLabel: 'min Rev',
                    maxRevLabel: 'max Rev'
                }
            }
        },
        opnManage: {
            opnCreatorDialog: {
                title: 'OPN creation',
                opnLabel: 'OPN name',
                linesChooser: {
                    fieldLabel: 'Choose line',
                    confirmMessage: 'Are you sure you want to create a line with that name?'
                },
                categoriesChooser: {
                    fieldLabel: 'Choose category',
                    confirmMessage: 'Are you sure you want to create a category with that name?'
                },
                familyChooser: {
                    fieldLabel: 'Choose family',
                    confirmMessage: 'Are you sure you want to create a family with that name?'
                },
                waferChooser: {
                    fieldLabel: 'Choose wafer',
                    confirmMessage: 'Are you sure you want to create a wafer with that name?'
                },
                dieChooser: {
                    fieldLabel: 'Choose die',
                    confirmMessage: 'Are you sure you want to create a die with that name?'
                }
            }
        },
        customersManage: {
            table: {
                customerID: 'ID',
                customerName: 'Customer name',
                BUApplicationName: 'BU Application',
                territoryName: 'Territory'
            }
        }
    }
};