import React, { FC, useEffect, useRef, useState } from 'react'
import { ChipCategory, ChipFamily } from "../../../../api/types/chipTypes";
import { useChipManage } from "../../../../api/hooks/useChipManage";
import { useLoaders } from "../../../../api/hooks/useLoaders";
import { usePrevious } from "../../../../api/hooks/usePrevious";
import s from "../OPNCreatorDialog.module.css";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import { ConfirmationDialog } from "../../../../ui_components/ConfirmationDialog";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../api/redux/store";

interface ChipFamilyChooserProps {
    selectedFamily: ChipFamily | null
    onFamilySelected: (family: ChipFamily | null) => void
    selectedCategory: ChipCategory | null
}

export const ChipFamilyChooser: FC<ChipFamilyChooserProps> = ({ selectedFamily, onFamilySelected, selectedCategory }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const { chipFamilies, getFamiliesList, createChipFamily } = useChipManage ()
    const { familiesLoading } = useLoaders ()
    const [ value, setValue ] = useState<string> ('')

    const prevAmount = usePrevious ({ value, selectedFamily })
    const timer: { current: ReturnType<typeof setTimeout> | null } = useRef (null)

    const [ openedConfirmDialog, setOpenedConfirmDialog ] = useState<boolean>(false)

    useEffect (() => {
        if (selectedCategory) getFamiliesList (null, selectedCategory.chipCategoryID)
        setValue('')
    }, [ selectedCategory ]) // eslint-disable-line

    useEffect (() => {
        //This effect is needed in order to create a delay and allow the user to type text.
        //If the timer did not finish and the user wrote a new character, then reset the timer
        if (!familiesLoading && timer.current) clearTimeout (timer.current)
        //If the previous value and the new value of the text field are different
        // (i.e., there have been changes), and also if the list of chips is not loaded at this time,
        // then we start the timer, after which the list will start loading.
        if (!familiesLoading && prevAmount && value !== prevAmount.value) {
            timer.current = setTimeout (() => {
                if (selectedFamily) getFamiliesList (value, selectedFamily.familyID)
            }, 1000)
        }

        //Reset the timer when the component will unmount
        return () => {
            if (timer.current) clearTimeout (timer.current)
        }
    }, [ value, familiesLoading, prevAmount?.value, selectedFamily ]) // eslint-disable-line

    //If before Family has selected and now it is undefined then load full list of a chips
    useEffect (() => {
        if (prevAmount?.selectedFamily !== null && selectedFamily === null && selectedCategory !== null) getFamiliesList (null, selectedCategory.chipCategoryID)
    }, [ selectedFamily, prevAmount, selectedCategory ]) // eslint-disable-line

    return <div className={ s.chooserRow }>
        <Autocomplete
            disabled={ selectedCategory === null }
            classes={ { root: s.autocomplete } }
            disablePortal
            clearOnBlur={false}
            renderInput={ (params) => <TextField { ...params } label={lang.pages.opnManage.opnCreatorDialog.familyChooser.fieldLabel}/> }
            options={ chipFamilies }
            onChange={ (event, family: ChipFamily | null) => onFamilySelected (family) }
            value={ selectedFamily }
            getOptionLabel={ (family: ChipFamily) => family.familyName }
            isOptionEqualToValue={ (family: ChipFamily, value: ChipFamily) => family.familyID === value.familyID }
            size={ 'small' }
            onInputChange={ (event, inputValue) => {
                setValue (inputValue)
                if (selectedFamily) onFamilySelected(null)
            } }
            inputValue={ value }
        />
        <IconButton color={'primary'} disabled={value === ""} onClick={() => {setOpenedConfirmDialog(true)}}>
            <AddIcon fontSize={ 'medium' } classes={ { root: s.icon } }/>
        </IconButton>
        { openedConfirmDialog && <ConfirmationDialog
            close={() => setOpenedConfirmDialog(false)}
            message={lang.pages.opnManage.opnCreatorDialog.familyChooser.confirmMessage}
            onConfirm={() => {
                if (selectedCategory) createChipFamily(value, selectedCategory.chipCategoryID)
            }}
        />}
    </div>
}