import {lightTheme} from "../../themes/lightTheme";
import {darkTheme} from "../../themes/darkTheme";
import {Action} from "../actions";
import {ActionType} from "../actionTypes";
import {ThemeId} from "../../themes/themeId";
import {ThemeStorage} from "../../storage/themeStorage";
import {getThemeById} from "../../themes/utils";

const initTheme = getThemeById(ThemeStorage.getThemeId() || ThemeId.LightTheme)

export const themeReducer = (state= initTheme, action: Action) => {
    switch (action.type) {
        case ActionType.SwitchTheme:
            switch (action.payload) {
                case ThemeId.LightTheme:
                    ThemeStorage.setThemeId(ThemeId.LightTheme)
                    return lightTheme
                case ThemeId.DarkTheme:
                    ThemeStorage.setThemeId(ThemeId.DarkTheme)
                    return darkTheme
                default:
                    return state
            }
        default:
            return state
    }
}