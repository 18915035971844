import {LangName, LangPack} from "../../languages/types";
import {Action} from "../actions";
import {LangStorage} from "../../storage/langStorage";
import {ActionType} from "../actionTypes";
import {getLanguageByName} from "../../languages/utils";

const initLang = getLanguageByName(LangStorage.getLangName() || LangName.EN)

export const langReducer = (state: LangPack = initLang, action: Action) => {
    switch (action.type) {
        case ActionType.SwitchLanguage:
            LangStorage.setLangName(action.payload)
            return getLanguageByName(action.payload)
        default:
            return state
    }
}