import React, { FC, useState } from 'react'
import {animation, Item, Menu, TriggerEvent, useContextMenu } from "react-contexify";
import s from './OPNManage.module.css'
import { ConfirmationDialog } from "../../ui_components/ConfirmationDialog";
import { ChipOPNView } from "../../api/types/chipTypes";
import { LinearProgress } from "@mui/material";
import { useChipManage } from "../../api/hooks/useChipManage";

interface OPNRowContextMenuProps {
    ID: string,
    chipOPN: ChipOPNView
}

export const OPNRowContextMenu: FC<OPNRowContextMenuProps> = ({ children, ID, chipOPN }) => {
    const { show } = useContextMenu ({ id: ID })
    const { removeOPNFromAdmin } = useChipManage()
    const [ showDeleteDialog, setDeleteDialog ] = useState<boolean>(false)
    const [ showForceDelete, setForceDelete ] = useState<boolean>(false)

    const [ deleting, setDeleting ] = useState<boolean> (false)

    const handleContextMenu = (event: TriggerEvent) => {
        event.preventDefault();
        show(event)
    }

    const deleteOPN = () => {
        setDeleting (true)
        removeOPNFromAdmin (chipOPN, false, (success, needForce) => {
            setDeleting (false)
            if (needForce) setForceDelete (true)
        })
    }

    const forceDeleteOPN = () => {
        setDeleting (true)
        removeOPNFromAdmin (chipOPN, true, () => {
            setDeleting (false)
        })
    }

    return <>
        <tr onContextMenu={ handleContextMenu } className={ s.row }>
            { children }
        </tr>
        { deleting && <LinearProgress color={ 'warning' }/> }
        <Menu
            animation={ animation.slide }
            id={ ID }
        >
            <Item
                onClick={ (itemParams) => {
                    if (itemParams.event.shiftKey) {
                        deleteOPN()
                    } else setDeleteDialog (true)
                } }>
                <div className={ s.menuItem }>
                    <div>Delete OPN</div>
                    <div className={ s.helperTextMenu }>(Hold SHIFT for silent delete)</div>
                </div>
            </Item>
        </Menu>
        { showDeleteDialog &&
        <ConfirmationDialog close={ () => setDeleteDialog (false) } message={ `Are you sure you want to delete ${chipOPN.opnName}?` } onConfirm={ deleteOPN }/> }
        { showForceDelete &&
        <ConfirmationDialog close={ () => setForceDelete (false) } message={ `OPN ${chipOPN.opnName} is being used by some client. Do you still want to delete it?` } onConfirm={ forceDeleteOPN }/> }
    </>
}