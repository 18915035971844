import React, { FC, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import {Dashboard} from "./pages/dashboard/Dashboard";
import {routes} from "./api/routes";
import {UIWrapper} from "./ui_components/UIWrapper";
import { useSelector } from "react-redux";
import { StoreState } from "./api/redux/store";
import Settings from "./api/settings";
import { Forecasts } from "./pages/forecasts/Forecasts";
import { Customers } from "./pages/customers/Customers";
import { PrelimAllocation } from "./pages/prelimAllocation/PrelimAllocation";
import { OPNManage } from "./pages/opnManage/opnManage";
import { CustomersManage } from "./pages/customersManage/CustomersManage";

export const App: FC = () => {
    const location = useLocation()
    const lang = useSelector((store: StoreState) => store.lang)

    useEffect(() => {
        const key = Object.keys(routes).find(key => routes[key].path === location.pathname)
        document.title = key? `${lang.routes[routes[key].name].title} - ${Settings.TITLE_NAME}` : Settings.TITLE_NAME
    }, [location.pathname, lang.routes])

    return <UIWrapper>
        <Routes>
            <Route/>
            <Route path={routes.dashboard.path} element={<Dashboard/>}/>
            <Route path={routes.forecast.path} element={<Forecasts/>}/>
            <Route path={routes.customers.path} element={<Customers/>}/>
            <Route path={routes.prelimAllocation.path} element={<PrelimAllocation/>}/>
            <Route path={routes.opnManage.path} element={<OPNManage/>}/>
            <Route path={routes.customersManage.path} element={<CustomersManage/>}/>
        </Routes>
    </UIWrapper>
}