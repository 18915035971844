import { ChipCategory, ChipDIE, ChipFamily, ChipLine, ChipOPNView, Wafer } from "../types/chipTypes";
import { useAuthorisation } from "./useAuthorisation";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { ApiResponse } from "../http_connects/types";
import { useCallback, useState } from "react";
import Loaders from "../redux/reducers/loadersReducer";
import axios from "axios";
import Settings from "../settings";
import FormData from "form-data";
import { ActionType } from "../redux/actionTypes";
import { showError } from "../redux/reducers/apiErrorReducer";

interface iUseChipManage {
    chips: ChipOPNView[]
    chipLines: ChipLine[]
    chipCategories: ChipCategory[]
    chipFamilies: ChipFamily[]
    wafers: Wafer[]
    chipDies: ChipDIE[]
    getLinesList: (search: string | null) => void
    getChipCategoriesList: (search: string | null, lineID: number) => void
    getFamiliesList: (search: string | null, chipCategoryID: number) => void
    getWafersList: () => void
    getDieList: (search: string | null, waferID: number) => void
    getFullOPNList: () => void
    createLine: (lineName: string) => void
    createChipCategory: (chipCategoryName: string, lineID: number) => void
    createChipFamily: (familyName: string, chipCategoryID: number) => void
    createWafer: (waferName: string) => void
    createChipDie: (dieName: string, waferID: number) => void
    createOPN: (opnName: string, dieID: number, familyID: number, onDone: () => void) => void
    removeOPNFromAdmin: (opn: ChipOPNView, forced: boolean, onDone: (success: boolean, needForce: boolean) => void) => void
}

interface GetFullOPNListResponse extends ApiResponse {
    OPNs: ChipOPNView[]
}

interface GetLinesListResponse extends ApiResponse {
    lines?: ChipLine[]
}

interface GetChipCategoriesListResponse extends ApiResponse {
    chipCategories?: ChipCategory[]
}

interface GetFamiliesListResponse extends ApiResponse {
    families?: ChipFamily[]
}

interface GetWafersListResponse extends ApiResponse {
    wafers?: Wafer[]
}

interface GetDieListResponse extends ApiResponse {
    dies?: ChipDIE[]
}

interface CreateLineResponse extends ApiResponse {
    line?: ChipLine
}

interface CreateChipCategoryResponse extends ApiResponse {
    category?: ChipCategory
}

interface CreateChipFamilyResponse extends ApiResponse {
    family?: ChipFamily
}

interface CreateWaferResponse extends ApiResponse {
    wafer?: Wafer
}

interface CreateChipDieResponse extends ApiResponse {
    die?: ChipDIE
}

interface CreateOPNResponse extends ApiResponse {
    chipOPN?: ChipOPNView
}

export const useChipManage = (): iUseChipManage => {
    const { token, user } = useAuthorisation ()
    const dispatch = useDispatch ()

    const chips = useSelector ((state: StoreState) => state.OPNsAdmin)

    const [ chipLines, setChipLines ] = useState<ChipLine[]> ([])
    const [ chipCategories, setChipCategories ] = useState<ChipCategory[]> ([])
    const [ chipFamilies, setChipFamilies ] = useState<ChipFamily[]> ([])
    const [ wafers, setWafers ] = useState<Wafer[]> ([])
    const [ chipDies, setChipDies ] = useState<ChipDIE[]> ([])

    const getFullOPNList = useCallback (() => {
        dispatch (Loaders.setOPNFullListLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetFullOPNList')
        data.append ('userID', user?.userID)
        data.append ('token', token)

        axios.post<GetFullOPNListResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setOPNFullListLoading (false))
                const { success, error, OPNs } = response.data
                if (success) dispatch ({ type: ActionType.SetOPNFullList, payload: OPNs })
                if (error && error.code === 46)
                    dispatch ({ type: ActionType.SetOPNFullList, payload: [] })
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const getLinesList = useCallback ((search: string | null) => {
        dispatch (Loaders.setLinesLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetLinesList')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        if (search) data.append ('search', encodeURIComponent (search))

        axios.post<GetLinesListResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setLinesLoading (false))
                const { success, error, lines } = response.data
                if (success && lines) setChipLines (lines)
                if (error && error.code === 47) setChipLines ([])
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const getChipCategoriesList = useCallback ((search: string | null, lineID: number) => {
        dispatch (Loaders.setCategoriesLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetChipCategoriesList')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('lineID', lineID)
        if (search) data.append ('search', encodeURIComponent (search))

        axios.post<GetChipCategoriesListResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setCategoriesLoading (false))
                const { success, error, chipCategories } = response.data
                if (success && chipCategories) setChipCategories (chipCategories)
                if (error && error.code === 48) setChipCategories ([])
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const getFamiliesList = useCallback ((search: string | null, chipCategoryID: number) => {
        dispatch (Loaders.setFamiliesLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetFamiliesList')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('chipCategoryID', chipCategoryID)
        if (search) data.append ('search', encodeURIComponent (search))

        axios.post<GetFamiliesListResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setFamiliesLoading (false))
                const { success, error, families } = response.data
                if (success && families) setChipFamilies (families)
                if (error && error.code === 49) setChipFamilies ([])
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const getWafersList = useCallback (() => {
        dispatch (Loaders.setWafersLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetWafersList')
        data.append ('userID', user?.userID)
        data.append ('token', token)

        axios.post<GetWafersListResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setWafersLoading (false))
                const { success, error, wafers } = response.data
                if (success && wafers) setWafers (wafers)
                if (error && error.code === 50) setWafers ([])
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const getDieList = useCallback ((search: string | null, waferID: number) => {
        dispatch (Loaders.setDiesLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetDieList')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('waferID', waferID)
        if (search) data.append ('search', encodeURIComponent (search))

        axios.post<GetDieListResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setDiesLoading (false))
                const { success, error, dies } = response.data
                if (success && dies) setChipDies (dies)
                if (error && error.code === 51) setChipDies ([])
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const createLine = useCallback ((lineName: string) => {
        dispatch (Loaders.setLinesLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'CreateLine')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('lineName', encodeURIComponent (lineName))

        axios.post<CreateLineResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setLinesLoading (false))
                const { success, error, line } = response.data
                if (success && line) getLinesList (line.lineName)
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, getLinesList, token, user?.userID ])

    const createChipCategory = useCallback((chipCategoryName: string, lineID: number) => {
        dispatch (Loaders.setCategoriesLoading(true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'CreateChipCategory')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('lineID', lineID)
        data.append ('chipCategoryName', encodeURIComponent (chipCategoryName))

        axios.post<CreateChipCategoryResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setCategoriesLoading (false))
                const { success, error, category } = response.data
                if (success && category) getChipCategoriesList(category.chipCategoryName, category.lineID)
                else if (error) dispatch (showError (error))
            })
    }, [dispatch, getChipCategoriesList, token, user?.userID])

    const createChipFamily = useCallback((familyName: string, chipCategoryID: number) => {
        dispatch (Loaders.setFamiliesLoading(true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'CreateChipFamily')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('chipCategoryID', chipCategoryID)
        data.append ('familyName', encodeURIComponent (familyName))

        axios.post<CreateChipFamilyResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setFamiliesLoading (false))
                const { success, error, family } = response.data
                if (success && family) getFamiliesList(family.familyName, family.chipCategoryID)
                else if (error) dispatch (showError (error))
            })
    }, [dispatch, getFamiliesList, token, user?.userID])

    const createWafer = useCallback((waferName: string) => {
        dispatch (Loaders.setWafersLoading(true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'CreateWafer')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('waferName', encodeURIComponent (waferName))

        axios.post<CreateWaferResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setWafersLoading (false))
                const { success, error, wafer } = response.data
                if (success && wafer) getWafersList()
                else if (error) dispatch (showError (error))
            })
    }, [dispatch, getWafersList, token, user?.userID])

    const createChipDie = useCallback((dieName: string, waferID: number) => {
        dispatch (Loaders.setDiesLoading(true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'CreateChipDie')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('waferID', waferID)
        data.append ('dieName', encodeURIComponent (dieName))

        axios.post<CreateChipDieResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setDiesLoading (false))
                const { success, error, die } = response.data
                if (success && die) getDieList(die.dieName, die.waferID)
                else if (error) dispatch (showError (error))
            })
    }, [dispatch, getDieList, token, user?.userID])

    const createOPN = useCallback((opnName: string, dieID: number, familyID: number, onDone: () => void) => {
        dispatch (Loaders.setOPNCreateLoading(true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'CreateOPN')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('dieID', dieID)
        data.append ('familyID', familyID)
        data.append ('opnName', encodeURIComponent (opnName))

        axios.post<CreateOPNResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setOPNCreateLoading (false))
                const { success, error, chipOPN } = response.data
                if (success && chipOPN) {
                    dispatch ({ type: ActionType.AddOPNToListFirst, payload: chipOPN })
                    onDone()
                }
                else if (error) dispatch (showError (error))
            })
    }, [dispatch, token, user?.userID])

    const removeOPNFromAdmin = useCallback ((opn: ChipOPNView, forced: boolean, onDone: (success: boolean, needForce: boolean) => void) => {
        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'DeleteOPN')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('opnID', opn.opnID)
        if (forced) data.append ('forced', 1)

        axios.post<ApiResponse> (Settings.API_HOST, data)
            .then (response => {
                const { success, error } = response.data
                onDone (success, error?.code === 75)
                if (success) dispatch ({ type: ActionType.RemoveOPNFromAdmin, payload: opn })
                if (error && error.code !== 75) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    return {
        chips,
        chipLines,
        chipCategories,
        chipFamilies,
        wafers,
        chipDies,
        getLinesList,
        getChipCategoriesList,
        getFamiliesList,
        getWafersList,
        getDieList,
        getFullOPNList,
        createLine,
        createChipCategory,
        createChipFamily,
        createWafer,
        createChipDie,
        createOPN,
        removeOPNFromAdmin
    }
}