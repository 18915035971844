import React, { FC } from 'react'
import s from './PrelimAllocationEditingDialog.module.css'
import { iPrelimQuarter } from "../prelimFuncs";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../../../api/redux/store";

interface PrelimQuarterFieldsProps {
    quarter: iPrelimQuarter
    onQTYkChanged: (QTYk: string|null) => void
    onMinRevChanged: (minRev: string|null) => void
    onMaxRevChanged: (maxRev: string|null) => void
}

export const PrelimQuarterFields: FC<PrelimQuarterFieldsProps> = ({ quarter, onQTYkChanged, onMinRevChanged, onMaxRevChanged }) => {
    const lang = useSelector ((store: StoreState) => store.lang)

    return <div className={ s.quarter }>
        { quarter.name }
        <TextField
            onChange={e => {
                if (e.target.value.match(/^\d*$/)) onQTYkChanged (e.target.value === '' ? null : e.target.value)
            }}
            value={ quarter.QTYk !== null ? quarter.QTYk : '' }
            label={ lang.pages.prelimAllocation.editPrelimAllocationDialog.quarter.quantityLabel }
            size={ 'small' }
        />
        <TextField
            onChange={ e => {
                let val = e.target.value.replace (',', '.')
                if (val.match (/^$|^[\d.]+$/)) onMinRevChanged (val === '' ? null : val)
            } }
            value={ quarter.minRev !== null ? quarter.minRev : '' }
            error={ quarter.minRev !== null && !quarter.minRev.match (/^(\d)+([.]\d+)?$/) }
            label={ lang.pages.prelimAllocation.editPrelimAllocationDialog.quarter.minRevLabel }
            size={ 'small' }
        />
        <TextField
            onChange={ e => {
                let val = e.target.value.replace (',', '.')
                if (val.match (/^$|^[\d.]+$/)) onMaxRevChanged (val === '' ? null : val)
            } }
            value={ quarter.maxRev !== null ? quarter.maxRev : '' }
            error={ quarter.maxRev !== null && !quarter.maxRev.match (/^(\d)+([.]\d+)?$/) }
            label={ lang.pages.prelimAllocation.editPrelimAllocationDialog.quarter.maxRevLabel }
            size={ 'small' }
        />
    </div>
}