import { AxiosRequestConfig } from "axios";

const AXIOS_CONFIG : AxiosRequestConfig<FormData> = {
    headers: {
        "Content-type": `multipart/form-data;`
    },
    responseType: 'json'
}

const HOST : string = "https://chip.burning-engineering.com/"

const API_HOST : string = `${HOST}backend/api.php`

const FADE_DURATION = {
    enter: 1000,
    exit: 500
}

const TITLE_NAME = "Chip planning system"

const Settings = {
    AXIOS_CONFIG,
    HOST,
    API_HOST,
    FADE_DURATION,
    TITLE_NAME
}

export default Settings