import React, { FC } from 'react'
import s from './CustomerChipRelationCreator.module.css'
import { TD } from "../TableComponents";
import { AddCustomer } from "./AddCustomer";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Comment } from "./Comment";
import { AddOpn } from "./AddOpn";
import { useCustomers } from "../../../api/hooks/useCustomers";

export const CustomerChipRelationCreator: FC = () => {
    const { newCustomerRelation, addCustomerRelation } = useCustomers()

    return <tr>
        <TD className={ s.td } fullWidth>
            <AddCustomer/>
        </TD>
        <TD className={ s.td } fullWidth>
            <AddOpn/>
        </TD>
        <TD className={ s.td } fullWidth cellClassName={ s.comment }>
            <Comment/>
            { newCustomerRelation?.customer && newCustomerRelation?.opn && <Fab color="primary" size={ 'small' } aria-label="save">
                <AddIcon onClick={() => addCustomerRelation()}/>
            </Fab> }
        </TD>
    </tr>
}