import {LangName} from "../languages/types";
import Storage from "./config";

const LANG_NAME = Storage.LS_PREFIX + 'LangName'

export class LangStorage {
    static getLangName () : LangName | null {
        const s = localStorage.getItem(LANG_NAME)
        if (s !== null) return JSON.parse(s) as LangName
        return null
    }

    static setLangName (lang: LangName) {
        localStorage.setItem(LANG_NAME, JSON.stringify(lang))
    }
}