import {Theme, ThemeId} from "./themeId";

export const darkTheme : Theme = {
    name: 'Dark Theme',
    id: ThemeId.DarkTheme,
    options: {
        palette: {
            primary: {
                main: '#5E5CE6',
            },
            secondary: {
                main: '#82D2FF',
            },
            error: {
                main: '#FF4672',
            },
            background: {
                default: '#2C2C2E',
                paper: '#343434',
            },
            text: {
                primary: '#fff',
                secondary: 'rgba(255, 255, 255, 0.6)',
                disabled: 'rgba(255, 255, 255, 0.3)'
            },
        },
    }
}