import React, { FC, useEffect, useRef, useState } from 'react'
import { ChipDIE, Wafer } from "../../../../api/types/chipTypes";
import { useChipManage } from "../../../../api/hooks/useChipManage";
import { useLoaders } from "../../../../api/hooks/useLoaders";
import { usePrevious } from "../../../../api/hooks/usePrevious";
import s from "../OPNCreatorDialog.module.css";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import { ConfirmationDialog } from "../../../../ui_components/ConfirmationDialog";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../api/redux/store";

interface DieChooserProps {
    selectedDie: ChipDIE | null
    onDieSelected: (die: ChipDIE | null) => void
    selectedWafer: Wafer | null
}

export const DieChooser: FC<DieChooserProps> = ({ selectedDie, onDieSelected, selectedWafer }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const { chipDies, getDieList, createChipDie } = useChipManage ()
    const { diesLoading } = useLoaders ()
    const [ value, setValue ] = useState<string> ('')

    const prevAmount = usePrevious ({ value, selectedDie })
    const timer: { current: ReturnType<typeof setTimeout> | null } = useRef (null)

    const [ openedConfirmDialog, setOpenedConfirmDialog ] = useState<boolean>(false)

    useEffect (() => {
        if (selectedWafer) getDieList (null, selectedWafer.waferID)
        setValue('')
    }, [ selectedWafer ]) // eslint-disable-line

    useEffect (() => {
        //This effect is needed in order to create a delay and allow the user to type text.
        //If the timer did not finish and the user wrote a new character, then reset the timer
        if (!diesLoading && timer.current) clearTimeout (timer.current)
        //If the previous value and the new value of the text field are different
        // (i.e., there have been changes), and also if the list of chips is not loaded at this time,
        // then we start the timer, after which the list will start loading.
        if (!diesLoading && prevAmount && value !== prevAmount.value) {
            timer.current = setTimeout (() => {
                if (selectedWafer) getDieList (value, selectedWafer.waferID)
            }, 1000)
        }

        //Reset the timer when the component will unmount
        return () => {
            if (timer.current) clearTimeout (timer.current)
        }
    }, [ value, diesLoading, prevAmount?.value, selectedWafer ]) // eslint-disable-line

    //If before Die has selected and now it is undefined then load full list of a chips
    useEffect (() => {
        if (prevAmount?.selectedDie !== null && selectedDie === null && selectedWafer !== null) getDieList (null, selectedWafer.waferID)
    }, [ selectedDie, prevAmount, selectedWafer ]) // eslint-disable-line

    return <div className={ s.chooserRow }>
        <Autocomplete
            disabled={ selectedWafer === null }
            classes={ { root: s.autocomplete } }
            disablePortal
            clearOnBlur={false}
            renderInput={ (params) => <TextField { ...params } label={lang.pages.opnManage.opnCreatorDialog.dieChooser.fieldLabel}/> }
            options={ chipDies }
            onChange={ (event, die: ChipDIE | null) => onDieSelected (die) }
            value={ selectedDie }
            getOptionLabel={ (die: ChipDIE) => die.dieName }
            isOptionEqualToValue={ (die: ChipDIE, value: ChipDIE) => die.dieID === value.dieID }
            size={ 'small' }
            onInputChange={ (event, inputValue) => {
                setValue (inputValue)
                if (selectedDie) onDieSelected(null)
            } }
            inputValue={ value }
        />
        <IconButton color={'primary'} disabled={value === ""} onClick={() => {setOpenedConfirmDialog(true)}}>
            <AddIcon fontSize={ 'medium' } classes={ { root: s.icon } }/>
        </IconButton>
        { openedConfirmDialog && <ConfirmationDialog
            close={() => setOpenedConfirmDialog(false)}
            message={lang.pages.opnManage.opnCreatorDialog.dieChooser.confirmMessage}
            onConfirm={() => {
                if (selectedWafer) createChipDie(value, selectedWafer.waferID)
            }}
        />}
    </div>
}