import { Action } from "../actions";
import { Customer, CustomersChipView } from "../../types/customer";
import { ActionType } from "../actionTypes";
import { ChipOPN } from "../../types/chipTypes";

export interface NewCustomerRelation {
    customer?: Customer
    opn?: ChipOPN
    comment?: string
}

interface CustomersStore {
    customers: Customer[]
    customersRelations: CustomersChipView[],
    customerRelation?: CustomersChipView,
    newCustomerRelation?: NewCustomerRelation
}

const init : CustomersStore = {
    customers: [],
    customersRelations: []
}

export const customersReducer = (state: CustomersStore = init, action: Action) : CustomersStore => {
    switch (action.type) {
        //Replace current array of customers list by new one
        case ActionType.SetCustomers:
            return { ...state, customers: action.payload }
        //Update customer obj in customers list
        case ActionType.ReplaceCustomer:
            return { ...state, customers: state.customers.map(relation => relation.customerID === action.payload.customerID ? action.payload : relation) }
        //Set selected customer to newCustomerRelation for further create new customer-chip relation
        case ActionType.SetCustomerToRelation:
            return { ...state, newCustomerRelation: { ...state.newCustomerRelation, customer: action.payload} }
        //Set selected chip to newCustomerRelation for further create new customer-chip relation
        case ActionType.SetOPNToRelation:
            return { ...state, newCustomerRelation: { ...state.newCustomerRelation, opn: action.payload} }
        //Set comment text to newCustomerRelation for further create new customer-chip relation
        case ActionType.SetCommentToRelation:
            return { ...state, newCustomerRelation: { ...state.newCustomerRelation, comment: action.payload} }
        //Reset newCustomerRelation after successful creation of relation
        case ActionType.ResetNewCustomerRelation:
            return { ...state, newCustomerRelation: undefined}
        //Replace current array of customers chips relations by new one
        case ActionType.SetCustomersRelations:
            return { ...state, customersRelations: action.payload }
        //Set single customer relation object on demand
        case ActionType.SetCustomerRelation:
            return { ...state, customerRelation: action.payload }
        //Add new customers chip relation row at first of array customersRelations
        case ActionType.AddFirstCustomersRelation:
            return { ...state, customersRelations: [action.payload].concat(state.customersRelations)}
        default:
            return state
    }
}