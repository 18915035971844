export enum LangName {
    EN= 'en'
}

interface RouteLang {
    title: string,
    shortMenuName?: string
}

export interface LangPack {
    lang: LangName,
    routes: {
        [index: string] : RouteLang
    }
    [index: string] : any
}

