import React, { FC, useEffect, useRef, useState } from 'react'
import { Autocomplete, TextField } from "@mui/material";
import { Territory } from "../../../api/types/commons";
import { useCustomersManage } from "../../../api/hooks/useCustomersManage";
import { useLoaders } from "../../../api/hooks/useLoaders";
import { usePrevious } from "../../../api/hooks/usePrevious";
import s from "./CustomerEditorRow.module.css"

interface TerritoryChooserProps {
    selectedTerritory: Territory | null
    onTerritorySelected: (territory: Territory|null) => void
}

export const TerritoryChooser: FC<TerritoryChooserProps> = ({ selectedTerritory, onTerritorySelected }) => {
    const { territoriesLoading } = useLoaders()
    const { territories, getTerritories } = useCustomersManage()
    const [ value, setValue ] = useState<string>('')

    const prevAmount = usePrevious ({ value, selectedTerritory })
    const timer: { current: ReturnType<typeof setTimeout> | null } = useRef (null)

    useEffect(() => {
        getTerritories(value)
    }, []) // eslint-disable-line

    useEffect (() => {
        //This effect is needed in order to create a delay and allow the user to type text.
        //If the timer did not finish and the user wrote a new character, then reset the timer
        if (!territoriesLoading && timer.current) clearTimeout (timer.current)
        //If the previous value and the new value of the text field are different
        // (i.e., there have been changes), and also if the list of chips is not loaded at this time,
        // then we start the timer, after which the list will start loading.
        if (!territoriesLoading && prevAmount && value !== prevAmount.value) {
            timer.current = setTimeout (() => getTerritories(value), 1000)
        }

        //Reset the timer when the component will unmount
        return () => {
            if (timer.current) clearTimeout (timer.current)
        }
    }, [ value, territoriesLoading, prevAmount?.value ]) // eslint-disable-line

    //If before OPN has selected and now it is undefined then load full list of a chips
    useEffect (() => {
        if (prevAmount?.selectedTerritory !== null && selectedTerritory === null) getTerritories(null)
    }, [ selectedTerritory, prevAmount ]) // eslint-disable-line

    return <Autocomplete
        classes={{root: s.autocomplete}}
        disablePortal
        options={ territories }
        renderInput={ params => <TextField { ...params } variant={ 'standard' }/> }
        size={'small'}
        value={ selectedTerritory }
        isOptionEqualToValue={ (territory: Territory, value: Territory) => territory.territoryID === value.territoryID }
        getOptionLabel={ (territory: Territory) => territory.territoryName }
        onChange={ (e, territory: Territory|null) => onTerritorySelected(territory)}
        onInputChange={ (event, inputValue) => {
            setValue (inputValue)
        } }
        inputValue={ value }
    />
}