import {darkTheme} from "./darkTheme";
import {lightTheme} from "./lightTheme";
import {Theme, ThemeId} from "./themeId";

export const getThemeById = (themeID: ThemeId) : Theme => {
    switch (themeID) {
        case ThemeId.DarkTheme:
            return darkTheme
        case ThemeId.LightTheme:
        default:
            return lightTheme
    }
}