import React from 'react';
import ReactDOM from 'react-dom';
import {App} from "./App";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter} from 'react-router-dom'
import {Provider, useSelector} from 'react-redux'
import store, {StoreState} from "./api/redux/store";
import {ThemeId} from "./api/themes/themeId";
import {Helmet} from "react-helmet";
import './index.css'
import ApiErrorDialog from "./ui_components/ApiErrorDialog";
import "react-contexify/dist/ReactContexify.css";

type ReduxWrapperProps = {
    children?: React.ReactNode;
};

const ReduxWrapper = ({ children }: ReduxWrapperProps) => <Provider store={store}>{children}</Provider>

const Index = () => {
    const themeOptions = useSelector((state : StoreState) => state.theme)
    const theme = createTheme(themeOptions.options)

    return <ThemeProvider theme={theme}>
        <BrowserRouter>
            <CssBaseline/>
            <Helmet>
                <link rel="stylesheet" type="text/css" href={'/css/' + ((themeOptions.id === ThemeId.DarkTheme) ? 'DarkTheme.css' : 'LightTheme.css')} />
            </Helmet>
            <App/>
        </BrowserRouter>
        <ApiErrorDialog/>
    </ThemeProvider>
}

ReactDOM.render(<ReduxWrapper><Index/></ReduxWrapper>,
    document.getElementById('root')
);
