import React, { FC } from 'react'
import s from './OPNManage.module.css'
import { OPNsTable } from "./OPNsTable";
import { AddOPNButton } from "./opnCreatorDialog/AddOPNButton";
import { useLoaders } from "../../api/hooks/useLoaders";
import { LinearProgress } from "@mui/material";
import { UserRole } from "../../api/types/userRole";
import { AuthWrapper } from "../../ui_components/AuthWrapper";

export const OPNManage: FC = () => {
    const { OPNsLoading } = useLoaders ()

    return <AuthWrapper accessedRoles={ [ UserRole.Moderator ] }>
        <div className={ s.root }>
            <AddOPNButton/>
            { OPNsLoading && <LinearProgress/> }
            <OPNsTable/>
        </div>
    </AuthWrapper>
}