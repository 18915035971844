import { PrelimPlanning } from "../types/prelimPlanning";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { useAuthorisation } from "./useAuthorisation";
import { useCallback } from "react";
import Loaders from "../redux/reducers/loadersReducer";
import axios from "axios";
import Settings from "../settings";
import FormData from "form-data";
import { ApiResponse } from "../http_connects/types";
import { ActionType } from "../redux/actionTypes";
import { showError } from "../redux/reducers/apiErrorReducer";

interface iUsePrelims {
    prelimPlannings: PrelimPlanning[]
    samplePrelimPlanning?: PrelimPlanning
    getPrelimPlannings: () => void
    updatePrelimPlanning: (prelimPlanning: PrelimPlanning) => void
}

interface GetPrelimPlanningsResponse extends ApiResponse {
    samplePrelimPlanning?: PrelimPlanning
    prelimPlannings?: PrelimPlanning[]
}

interface UpdatePrelimPlanningResponse extends ApiResponse {
    prelimPlanning?: PrelimPlanning
}

export const usePrelims = () : iUsePrelims => {
    const { token, user } = useAuthorisation ()
    const dispatch = useDispatch ()
    const { prelimPlannings, samplePrelimPlanning }= useSelector ((state: StoreState) => state.prelim)

    const getPrelimPlannings = useCallback(() => {
        dispatch (Loaders.setPrelimPlanningsLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetPrelimPlannings')
        data.append ('userID', user?.userID)
        data.append ('token', token)

        axios.post<GetPrelimPlanningsResponse> (Settings.API_HOST, data)
            .then (response => {
                const { prelimPlannings, samplePrelimPlanning, error, success } = response.data
                dispatch (Loaders.setPrelimPlanningsLoading (false))
                if (success) {
                    dispatch ({ type: ActionType.SetPrelimPlannings, payload: prelimPlannings })
                    dispatch ({ type: ActionType.SetSamplePrelimPlanning, payload: samplePrelimPlanning })
                }
                if (error && error.code === 45) dispatch ({ type: ActionType.SetPrelimPlannings, payload: [] })
                else if (error)  dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const updatePrelimPlanning = useCallback((prelimPlanning: PrelimPlanning) => {
        dispatch (Loaders.setPrelimPlanningUpdateLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'UpdatePrelimPlanning')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('prelimPlanning', encodeURIComponent(JSON.stringify(prelimPlanning)))

        axios.post<UpdatePrelimPlanningResponse> (Settings.API_HOST, data)
            .then (response => {
                const { prelimPlanning, error, success } = response.data
                dispatch (Loaders.setPrelimPlanningUpdateLoading (false))
                if (success) dispatch ({ type: ActionType.UpdatePrelimPlanning, payload: prelimPlanning })
                if (error)  dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    return { prelimPlannings, samplePrelimPlanning, getPrelimPlannings, updatePrelimPlanning }
}