import React, { FC, useEffect, useState } from 'react'
import { Forecast } from "../../../api/types/forecast";
import Settings from "../../../api/settings";
import { useSelector } from "react-redux";
import { StoreState } from "../../../api/redux/store";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { iQuarter, Quarter } from "./Quarter";
import s from './EditForecastDialog.module.css'
import { useForecasts } from "../../../api/hooks/useForecasts";

interface EditForecastDialogProps {
    forecast: Forecast
    close: () => void
}

export const EditForecastDialog: FC<EditForecastDialogProps> = ({ forecast, close }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const [ opened, setOpened ] = useState (true)
    const [ quarters, setQuarters ] = useState<iQuarter[]> ([])
    const { updateForecast } = useForecasts()

    const pendingClose = () => {
        setOpened (false);
        setTimeout (() => close (), 500)
    }

    useEffect (() => {
        if (forecast) {
            setQuarters ([
                {
                    name: forecast.quarter1,
                    QTY: forecast.quarter1QTY,
                    DC: forecast.quarter1DC
                },
                {
                    name: forecast.quarter2,
                    QTY: forecast.quarter2QTY,
                    DC: forecast.quarter2DC
                },
                {
                    name: forecast.quarter3,
                    QTY: forecast.quarter3QTY,
                    DC: forecast.quarter3DC
                },
                {
                    name: forecast.quarter4,
                    QTY: forecast.quarter4QTY,
                    DC: forecast.quarter4DC
                }
            ])
        }
    }, []) // eslint-disable-line

    const hasDifference = () => {
        let has = false
        quarters.forEach((quarter, index) => {
            switch(index) {
                case 0:
                    forecast?.quarter1DC !== quarter.DC && (has = true)
                    forecast?.quarter1QTY !== quarter.QTY && (has = true)
                    break
                case 1:
                    forecast?.quarter2DC !== quarter.DC && (has = true)
                    forecast?.quarter2QTY !== quarter.QTY && (has = true)
                    break
                case 2:
                    forecast?.quarter3DC !== quarter.DC && (has = true)
                    forecast?.quarter3QTY !== quarter.QTY && (has = true)
                    break
                case 3:
                    forecast?.quarter4DC !== quarter.DC && (has = true)
                    forecast?.quarter4QTY !== quarter.QTY && (has = true)
                    break
            }
        })
        return has
    }

    const hasErrors = () => {
        let has = false
        quarters.forEach(q => (q.DC !== null && !q.DC.match(/^(\d)+([.]\d+)?$/)) && (has = true))
        return has
    }

    const prepareNewForecast = () : Forecast => {
        return {
            ...forecast,
            quarter1DC: quarters[0].DC,
            quarter1QTY: quarters[0].QTY,
            quarter2DC: quarters[1].DC,
            quarter2QTY: quarters[1].QTY,
            quarter3DC: quarters[2].DC,
            quarter3QTY: quarters[2].QTY,
            quarter4DC: quarters[3].DC,
            quarter4QTY: quarters[3].QTY,
        }
    }

    return <Dialog
        maxWidth={ 'sm' }
        fullWidth
        open={ opened }
        transitionDuration={ Settings.FADE_DURATION }
        onClose={ pendingClose }
    >
        <DialogTitle>
            { lang.pages.forecast.editForecastDialog.title.toUpperCase () }
        </DialogTitle>
        <DialogContent className={s.content}>
            { quarters.map((quarter, i) => <Quarter
                key={i}
                quarter={quarter}
                onQTYChanged={(qty) => {
                    let quartersCopy = [...quarters]
                    quartersCopy[i] = { ...quartersCopy[i], QTY: qty }
                    setQuarters( quartersCopy );
                }}
                onDCChanged={(dc) => {
                    let quartersCopy = [...quarters]
                    quartersCopy[i] = { ...quartersCopy[i], DC: dc }
                    setQuarters( quartersCopy );
                }}
            />)}
        </DialogContent>
        <DialogActions>
            <Button onClick={ pendingClose } color={ 'secondary' }>
                { lang.ui.commons.cancelBTN }
            </Button>
            <Button color={ 'primary' } disabled={ !hasDifference() || hasErrors() } onClick={ () => {
                let newForecast = prepareNewForecast()
                updateForecast(newForecast, forecast)
                pendingClose ()
            } }>
                { lang.ui.commons.addBTN }
            </Button>
        </DialogActions>
    </Dialog>
}