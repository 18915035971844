import React, { FC, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Settings from "../../../api/settings";
import { useSelector } from "react-redux";
import { StoreState } from "../../../api/redux/store";
import { CustomersAutoList } from "./CustomersAutoList";
import { Customer } from "../../../api/types/customer";
import { ChipsAutoList } from "./ChipsAutoList";
import { ChipOPN } from "../../../api/types/chipTypes";
import s from './AddForecastDialog.module.css'
import { useCustomers } from "../../../api/hooks/useCustomers";
import { useForecasts } from "../../../api/hooks/useForecasts";

interface AddForecastDialogProps {
    close: () => void
}

export const AddForecastDialog: FC<AddForecastDialogProps> = ({ close }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const [ opened, setOpened ] = useState (true)
    const [ customer, setCustomer ] = useState<Customer> ()
    const [ chip, setChip ] = useState<ChipOPN | null> (null)
    const { getCustomerRelation, customerRelation, clearCustomerRelation } = useCustomers ()
    const { addForecast } = useForecasts()

    const pendingClose = () => {
        setOpened (false);
        setTimeout (() => close (), 500)
    }

    useEffect (() => {
        if (customer && chip) getCustomerRelation (customer.customerID, chip.opnID)
        if (customer && !Boolean(chip)) clearCustomerRelation()
    }, [ customer, chip ]) // eslint-disable-line

    return <Dialog maxWidth={ 'xs' } fullWidth open={ opened } transitionDuration={ Settings.FADE_DURATION }
                   onClose={ pendingClose }>
        <DialogTitle>{ lang.pages.forecast.addForecastDialog.title.toUpperCase () }</DialogTitle>
        <DialogContent className={ s.content }>
            <CustomersAutoList customer={ customer } onSelected={ c => {
                setChip(null)
                setCustomer(c)
            } }/>
            <ChipsAutoList onSelect={ setChip } chip={ chip } customer={ customer }/>
            { customerRelation?.comment && <Typography variant={ 'body1' }>
                { customerRelation.comment }
            </Typography> }
        </DialogContent>
        <DialogActions>
            <Button onClick={ pendingClose } color={ 'secondary' }>
                { lang.ui.commons.cancelBTN }
            </Button>
            <Button color={ 'primary' } disabled={!Boolean(customerRelation)} onClick={() => {
                if (customerRelation) addForecast (customerRelation.customerID, customerRelation.opnID)
                pendingClose()
            }}>
                { lang.ui.commons.addBTN }
            </Button>
        </DialogActions>
    </Dialog>
}