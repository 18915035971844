import React, { FC, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../api/redux/store";
import Settings from "../../api/settings";
import { clearUser } from "../../api/redux/reducers/userReducer";
import { clearToken } from "../../api/redux/reducers/tokenReducer";

interface LogOutDialogProps {
    close: () => void
}

export const LogOutDialog: FC<LogOutDialogProps> = ({ close }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const [ opened, setOpened ] = useState(true)
    const dispatch = useDispatch()

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog maxWidth={ 'xs' } fullWidth onClose={pendingClose} open={ opened } transitionDuration={ Settings.FADE_DURATION }>
        <DialogTitle>{ lang.ui.logOutDialog.title }</DialogTitle>
        <DialogContent>{ lang.ui.logOutDialog.content }</DialogContent>
        <DialogActions>
            <Button onClick={pendingClose} autoFocus color={ 'primary' } variant={ 'contained' }>{ lang.ui.commons.noBTN }</Button>
            <Button onClick={() => {
                dispatch(clearUser())
                dispatch(clearToken())
                pendingClose()
            }} color={ 'warning' } variant={ 'contained' }>{ lang.ui.commons.yesBTN }</Button>
        </DialogActions>
    </Dialog>
}