import React, { FC, useEffect, useState } from 'react'
import s from './ForecastTable.module.css'
import { Forecast } from "../../../api/types/forecast";
import { extractForecastQuarters, getCurrentQuarter, getQuarterByName, multiplyStrings } from "./forcastFuncs";
import { useSelector } from "react-redux";
import { StoreState } from "../../../api/redux/store";
import { Skeleton } from "@mui/material";
import { useLoaders } from "../../../api/hooks/useLoaders";
import { EditForecastDialog } from "../editForecastDialog/EditForecastDialog";

interface ForecastTableProps {
    forecasts: Array<Forecast>
}

const currentQuarter = getCurrentQuarter ()

export const ForecastTable: FC<ForecastTableProps> = ({ forecasts = [] }) => {
    const lang = useSelector ((state: StoreState) => state.lang)
    const [ forecastQuarters, setForecastQuarters ] = useState<string[]> ([])
    const { addForecastLoading } = useLoaders ()

    const [ editForecast, setEditForecast ] = useState<Forecast | null> (
        // {"forecastID": 9,
        //     "customerID": 7,
        //     "opnID": 2,
        //     "userID": 2,
        //     "lastUpdate": "2022-02-09 19:46:45",
        //     "customerName": "Walker Group",
        //     "opnName": "EFR32BG22",
        //     "accountManager": "Joan Morgan",
        //     "quarterCurrent": null,
        //     "quarter1": null,
        //     "quarter1QTY": null,
        //     "quarter1DC": null,
        //     "quarter2": null,
        //     "quarter2QTY": null,
        //     "quarter2DC": null,
        //     "quarter3": null,
        //     "quarter3QTY": null,
        //     "quarter3DC": null,
        //     "quarter4": null,
        //     "quarter4QTY": null,
        //     "quarter4DC": null,
        //     "territoryName": "Territory test"
        // }
    )

    useEffect (() => {
        setForecastQuarters (extractForecastQuarters (forecasts, currentQuarter))
    }, [ forecasts ]) // eslint-disable-line

    return <>
        <div className={ s.root }>
            <table>
                <thead>
                    <tr>
                        <TH>{ lang.pages.forecast.table.customerName }</TH>
                        <TH>{ lang.pages.forecast.table.partNumber }</TH>
                        <TH>{ lang.pages.forecast.table.accountManager }</TH>
                        <TH>{ lang.pages.forecast.table.territory }</TH>
                        {/*<TH className={ s.past }>Q2’2021</TH>*/ }
                        {/*<TH className={ s.past }>Q3’2021</TH>*/ }
                        {/*<TH className={ s.past }>Q4’2021</TH>*/ }
                        {/*<TH className={ s.past }>Q1’2022</TH>*/ }
                        { forecastQuarters.map ((quarters: string) => <TH key={ quarters }
                                                                          className={ s.forecast }>
                            { quarters }
                        </TH>) }
                    </tr>
                </thead>
                <tbody>
                    { addForecastLoading && <tr>
                        <TD><Skeleton variant="text"/></TD>
                        <TD><Skeleton variant="text"/></TD>
                        <TD><Skeleton variant="text"/></TD>
                        <TD><Skeleton variant="text"/></TD>
                        { forecastQuarters.map ((quarter: string) => <TD key={ quarter }><Skeleton
                            variant="text"/></TD>) }
                    </tr> }
                    { forecasts.map ((forecast) => <tr className={ `${ s.rowArrowCursor } ${ s.noSelect }` }
                                                       onClick={ (e) => e.detail === 2 && setEditForecast (forecast) }
                                                       key={ forecast.forecastID }>
                        <TD>{ forecast.customerName }</TD>
                        <TD>{ forecast.opnName }</TD>
                        <TD>{ forecast.accountManager }</TD>
                        <TD>{ forecast.territoryName }</TD>
                        {/*<TD fullWidth className={ s.past }>*/ }
                        {/*    <span>10</span>*/ }
                        {/*    <span>20</span>*/ }
                        {/*</TD>*/ }
                        {/*<TD fullWidth className={ s.past }>*/ }
                        {/*    <span>10</span>*/ }
                        {/*    <span>20</span>*/ }
                        {/*</TD>*/ }
                        {/*<TD fullWidth className={ s.past }>*/ }
                        {/*    <span>10</span>*/ }
                        {/*    <span>20</span>*/ }
                        {/*</TD>*/ }
                        {/*<TD fullWidth className={ s.past }>*/ }
                        {/*    <span>10</span>*/ }
                        {/*    <span>20</span>*/ }
                        {/*</TD>*/ }
                        { forecastQuarters.map ((quarter: string) => {
                            const q = getQuarterByName (quarter, forecast)
                            return <TD fullWidth className={ s.forecast }
                                       key={ `${ quarter }-f${ forecast.forecastID }` }>
                                { q !== null && <>
                                    <span>{ q.QTY ? q.QTY : '--' }</span>
                                    <span>{ q.DC ? q.DC : '--' }$</span>
                                    <span>{ multiplyStrings (q.QTY, q.DC) }$</span>
                                </> }
                            </TD>
                        }) }
                    </tr>) }
                </tbody>
            </table>
        </div>
        { editForecast && <EditForecastDialog close={ () => setEditForecast (null) } forecast={ editForecast }/> }
    </>
}

interface CellProps {
    children?: React.ReactNode
}

const Cell: FC<CellProps> = ({ children }) => <div className={ s.cell }>
    { children }
</div>

interface TProps extends CellProps {
    className?: string
    fullWidth?: boolean
}

const TH: FC<TProps> = ({ children, className }) => <th className={ className }><Cell>{ children }</Cell></th>

const TD: FC<TProps> = ({ children, className, fullWidth = false }) => <td
    className={ `${ className }${ fullWidth ? (' ' + s.full) : "" }` }><Cell>{ children }</Cell></td>