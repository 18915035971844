import UserStorage from "../../storage/userStorage";
import { Action } from "../actions";
import { ActionType } from "../actionTypes";

const initToken = UserStorage.getToken()

export const tokenReducer = (state : string | null = initToken, action: Action) => {
    switch (action.type) {
        case ActionType.SetToken:
            UserStorage.setToken(action.payload)
            return action.payload
        case ActionType.ClearToken:
            UserStorage.removeToken()
            return null
        default: return state
    }
}

export const setToken = (token: string) => {
    return { type: ActionType.SetToken, payload: token }
}

export const clearToken = () => {
    return { type: ActionType.ClearToken }
}