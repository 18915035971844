import UserStorage, { User } from "../../storage/userStorage";
import { Action } from "../actions";
import { ActionType } from "../actionTypes";

const initUser = UserStorage.getUser()

export const userReducer = (state : User | null = initUser, action: Action) => {
    switch (action.type) {
        case ActionType.SetUser:
            UserStorage.setUser(action.payload)
            return action.payload
        case ActionType.ClearUser:
            UserStorage.removeUser()
            return null
        default: return state
    }
}

export const clearUser = () => {
    return { type: ActionType.ClearUser }
}