import React, { FC } from 'react'
import { useCustomers } from "../../../api/hooks/useCustomers";
import { TextField } from "@mui/material";
import s from "./CustomerChipRelationCreator.module.css";

export const Comment: FC = () => {
    const { newCustomerRelation, setComment } = useCustomers ()
    return <TextField
        classes={{root: s.autocomplete}}
        size={'small'}
        label='Comment'
        onChange={e => setComment(e.target.value)}
        value={newCustomerRelation? newCustomerRelation.comment : ''}
    />
}