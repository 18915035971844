import React, { FC, useEffect, useState } from 'react'
import { UserRole } from "../api/types/userRole";
import { useUsers } from "../api/hooks/useUsers";
import s from './AuthWrapper.module.css'

interface AuthWrapperProps {
    accessedRoles?: UserRole[]
}

export const AuthWrapper: FC<AuthWrapperProps> = ({ accessedRoles, children }) => {
    const { user, userAccessed } = useUsers ()
    const [ accessed, setAccessed ] = useState<boolean> ()

    useEffect (() => {
        let acd: boolean
        if (user) {
            if (accessedRoles !== undefined) {
                acd = userAccessed (accessedRoles)
            } else acd = true
        } else acd = false
        setAccessed (acd)
    }, [ accessedRoles, user, userAccessed ])

    return <>
        { accessed ? children :
            <div className={ s.accessDenied }>
                <div className={ s.row }>
                    <div className={ s.deniedPic }/>
                    <div className={ s.access403 }>403</div>
                </div>
                <div className={ s.title }>
                    You do not have access to this page!
                </div>
            </div> }
    </>
}