import React, { FC, useEffect, useState } from 'react'
import { TD } from "../../customers/TableComponents";
import { useLoaders } from "../../../api/hooks/useLoaders";
import { CustomerView } from "../../../api/types/customer";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import s from "./CustomerEditorRow.module.css"
import { BUApplicationChooser } from "./BUApplicationChooser";
import { BUSegmentApplication, Territory } from "../../../api/types/commons";
import { TerritoryChooser } from "./TerritoryChooser";
import AddIcon from "@mui/icons-material/AddCircle";
import SaveIcon from '@mui/icons-material/Save';
import { useCustomersManage } from "../../../api/hooks/useCustomersManage";

interface CustomerEditorRowProps {
    editedCustomer: CustomerView | null
    onDone?: () => void
}

const init : CustomerView = {
    customerID: null,
    customerName: '',
    BUSegmentApplicationsID: null,
    BUApplicationName: '',
    territoryID: null,
    territoryName: '',
    userID: null
}

export const CustomerEditorRow: FC<CustomerEditorRowProps> = ({ editedCustomer = init, onDone }) => {
    const { customerAddLoading } = useLoaders()
    const [ BUApplication, setBUApplication ] = useState<BUSegmentApplication|null>(null)
    const [ territory, setTerritory ] = useState<Territory|null>(null)
    const [ customerName, setCustomerName ] = useState<string>('')
    const { updateCustomer } = useCustomersManage()

    useEffect(() => {
        if (editedCustomer) {
            if (editedCustomer.BUSegmentApplicationsID) {
                setBUApplication({
                    BUSegmentApplicationsID: editedCustomer.BUSegmentApplicationsID,
                    BUApplicationName: editedCustomer.BUApplicationName,
                    BUSegmentID: -1
                })
            }
            if (editedCustomer.territoryID) {
                setTerritory({
                    territoryID: editedCustomer.territoryID,
                    territoryName: editedCustomer.territoryName,
                    officeID: -1
                })
            }
            if (editedCustomer.customerName) {
                setCustomerName(editedCustomer.customerName)
            }
        }
    }, []) // eslint-disable-line

    return <tr>
        <TD>{ editedCustomer ? editedCustomer.customerID : '' }</TD>
        <TD>
            <TextField
                fullWidth
                variant={ 'standard' }
                size={ 'small' }
                onChange={ e => setCustomerName (e.target.value) }
                value={ customerName }
            />
        </TD>
        <TD><BUApplicationChooser selectedBUApplication={ BUApplication } onSelectedBUApplication={ setBUApplication }/></TD>
        <TD cellClassName={ s.comment }>
            <TerritoryChooser selectedTerritory={ territory } onTerritorySelected={ setTerritory }/>
            <IconButton
                color={ 'primary' }
                disabled={ customerAddLoading || customerName === '' || BUApplication === null || territory === null }
                onClick={() => {
                    if (territory && BUApplication) updateCustomer (
                        { ...(editedCustomer ? editedCustomer : init), customerName: customerName, territoryID: territory.territoryID, BUSegmentApplicationsID: BUApplication.BUSegmentApplicationsID },
                        success => {
                            if (success) {
                                setCustomerName('')
                                setTerritory(null)
                                setBUApplication(null)
                                if (onDone) onDone()
                            }
                        })
                }}
            >
                { customerAddLoading ? <CircularProgress size={18}/> : editedCustomer ?
                    <SaveIcon fontSize={ 'medium' } classes={ { root: s.icon } }/> :
                    <AddIcon fontSize={ 'medium' } classes={ { root: s.icon } }/> }
            </IconButton>
        </TD>
    </tr>
}