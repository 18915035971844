import { ApiResponse } from "../http_connects/types";
import { Forecast } from "../types/forecast";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { useCallback } from "react";
import Loaders from "../redux/reducers/loadersReducer";
import axios from "axios";
import Settings from "../settings";
import FormData from "form-data";
import { ActionType } from "../redux/actionTypes";
import { showError } from "../redux/reducers/apiErrorReducer";
import { useAuthorisation } from "./useAuthorisation";

interface GetForecastsResponse extends ApiResponse {
    forecasts?: Array<Forecast>
}

interface AddForecastResponse extends ApiResponse {
    forecast?: Forecast
}

interface UpdateForecastResponse extends ApiResponse {
    forecast?: Forecast
}

interface iUseForecasts {
    forecasts: Forecast[]
    getForecasts: (forecastID?: number | null) => void
    addForecast: (customerID: number, opnID: number) => void
    updateForecast: (newForecast: Forecast, oldForecast: Forecast) => void
}

export const useForecasts = (): iUseForecasts => {
    const { token, user } = useAuthorisation ()
    const dispatch = useDispatch ()
    const forecasts = useSelector ((state: StoreState) => state.forecasts)

    const getForecasts = useCallback (
        (forecastID?: number | null) => {
            dispatch (Loaders.setForecastsLoading (true))

            axios.create ({ ...Settings.AXIOS_CONFIG })

            const data = new FormData ();
            data.append ('action', 'GetForecasts')
            data.append ('userID', user?.userID)
            data.append ('token', token)

            axios.post<GetForecastsResponse> (Settings.API_HOST, data)
                .then (response => {
                    const { forecasts, success, error } = response.data
                    dispatch (Loaders.setForecastsLoading (false))
                    if (success) {
                        dispatch ({ type: ActionType.AppendForecasts, payload: forecasts })
                    }
                    if (error) dispatch (showError (error))
                })
        },
        [ dispatch, token, user?.userID ]
    )

    const addForecast = useCallback ((customerID: number, opnID: number) => {
        dispatch (Loaders.setAddForecastLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'AddForecast')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('customerID', customerID)
        data.append ('opnID', opnID)

        axios.post<AddForecastResponse> (Settings.API_HOST, data)
            .then (response => {
                const { forecast, success, error } = response.data
                dispatch (Loaders.setAddForecastLoading (false))
                if (success) {
                    dispatch ({ type: ActionType.AddForecastFirst, payload: forecast })
                }
                if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const updateForecast = useCallback ((newForecast: Forecast, oldForecast: Forecast) => {
        dispatch (Loaders.setUpdateForecastLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'UpdateForecast')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('forecast', JSON.stringify(newForecast))

        axios.post<UpdateForecastResponse> (Settings.API_HOST, data)
            .then (response => {
                const { forecast, success, error } = response.data
                dispatch (Loaders.setUpdateForecastLoading (false))
                if (success) {
                    dispatch ({ type: ActionType.RemoveForecast, payload: oldForecast })
                    dispatch ({ type: ActionType.AddForecastFirst, payload: forecast })
                }
                if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    return { forecasts, getForecasts, addForecast, updateForecast }
}