import React, {FC} from 'react'
import {ThemeSwitcher} from "./ThemeSwitcher";
import s from './TopBar.module.css'
import {Route, routes} from "../../api/routes";
import {MenuItem} from "./MenuItem";
import {UserMenu} from "./UserMenu";
import { useSelector } from "react-redux";
import { StoreState } from "../../api/redux/store";
import { InlineLogin } from "../login/InlineLogin";

const moderatorGroup: Route[] = [
    routes.opnManage,
    routes.customersManage
]

const sellersGroup: Route[] = [
    routes.customers,
    routes.forecast,
    routes.prelimAllocation,
    routes.finalAllocation
]

const businessUnitsGroup: Route[] = [
    routes.forecast,
    routes.prelimWaferPlanning,
    routes.waferPlanning,
    routes.prelimAllocation,
    routes.finalAllocation
]

const getMenuRoutes = (userRoleID: number | null) : Route[] => {
    switch (userRoleID) {
        case 1:
            return moderatorGroup
        case 2:
            return sellersGroup
        case 3:
            return businessUnitsGroup
        default:
            return []
    }
}

export const TopBar: FC = () => {
    const user = useSelector((state: StoreState) => state.user)
    const token = useSelector((state: StoreState) => state.token)

    return <div id='topBar' className={ s.root }>
        <div className={ s.menuList }>
            { getMenuRoutes (user ? user.userRoleID : null).map (route => <MenuItem key={ route.name } route={ route }/>) }
        </div>
        { (user === null || token === null) ? <InlineLogin/> : <UserMenu/>}
        <ThemeSwitcher/>
    </div>
}