import { Forecast } from "../../../api/types/forecast";

const q2Num = (quarter: string): number => {
    const [ q, y ] = quarter.replace (/[qQ]/, '').split ("'")
    return Number.parseInt ((y + q), 10)
}

export const extractForecastQuarters = (forecasts: Forecast[], lastQuarter: string): string[] => {
    const quarters: string[] = []
    forecasts.forEach ((forecast => {
        if (forecast.quarter1 !== null) quarters.push (forecast.quarter1)
        if (forecast.quarter2 !== null) quarters.push (forecast.quarter2)
        if (forecast.quarter3 !== null) quarters.push (forecast.quarter3)
        if (forecast.quarter4 !== null) quarters.push (forecast.quarter4)
    }))
    return Array.from (new Set (quarters)).sort ((a: string, b: string): number => q2Num (a) < q2Num (b) ? -1 : 1).filter ((v: string): boolean => q2Num (v) > q2Num (lastQuarter))
}

interface Quarter {
    name: string
    QTY: string | null
    DC: string | null
}

export const getQuarterByName = (quarterName: string, forecast: Forecast): Quarter | null => {
    if (forecast.quarter1 === quarterName) return {
        name: quarterName,
        DC: forecast.quarter1DC,
        QTY: forecast.quarter1QTY
    }
    else if (forecast.quarter2 === quarterName) return {
        name: quarterName,
        DC: forecast.quarter2DC,
        QTY: forecast.quarter2QTY
    }
    else if (forecast.quarter3 === quarterName) return {
        name: quarterName,
        DC: forecast.quarter3DC,
        QTY: forecast.quarter3QTY
    }
    else if (forecast.quarter4 === quarterName) return {
        name: quarterName,
        DC: forecast.quarter4DC,
        QTY: forecast.quarter4QTY
    }
    else return null
}

export const multiplyStrings = (qty: string | null, dc: string | null): string => {
    if (qty && dc) {
        return (Math.round (Number.parseInt (qty, 10) * Number.parseFloat (dc) * 10) / 10).toFixed (2).toString ()
    } else return '--'
}

export const getCurrentQuarter = (): string => {
    const date = new Date()
    return `q${Math.floor(date.getMonth()/3) + 1}'${date.getFullYear()}`
}