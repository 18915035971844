import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react'
import s from "./CustomerChipRelationCreator.module.css";
import { Autocomplete, TextField } from "@mui/material";
import { useChips } from "../../../api/hooks/useChips";
import { usePrevious } from "../../../api/hooks/usePrevious";
import { useCustomers } from "../../../api/hooks/useCustomers";
import { useLoaders } from "../../../api/hooks/useLoaders";
import { ChipOPN } from "../../../api/types/chipTypes";

export const AddOpn: FC = () => {
    const { OPNsLoading } = useLoaders ()
    const { newCustomerRelation, setOPN } = useCustomers ()

    const { chips, loadOPNs } = useChips ()
    const [ value, setValue ] = useState ('')

    const prevAmount = usePrevious ({ value, newCustomerRelation })

    const timer: { current: ReturnType<typeof setTimeout> | null } = useRef (null)

    useEffect (() => {
        loadOPNs ()
    }, []) // eslint-disable-line

    useEffect (() => {
        //This effect is needed in order to create a delay and allow the user to type text.
        //If the timer did not finish and the user wrote a new character, then reset the timer
        if (!OPNsLoading && timer.current) clearTimeout (timer.current)
        //If the previous value and the new value of the text field are different
        // (i.e., there have been changes), and also if the list of chips is not loaded at this time,
        // then we start the timer, after which the list will start loading.
        if (!OPNsLoading && prevAmount && value !== prevAmount.value) {
            timer.current = setTimeout (() => loadOPNs (value), 1000)
        }

        //Reset the timer when the component will unmount
        return () => {
            if (timer.current) clearTimeout (timer.current)
        }
    }, [ value, OPNsLoading, prevAmount?.value ]) // eslint-disable-line


    //If before OPN has selected and now it is undefined then load full list of a chips
    useEffect (() => {
        if (prevAmount?.newCustomerRelation?.opn !== undefined && newCustomerRelation?.opn === undefined) loadOPNs ()
    }, [ newCustomerRelation, prevAmount ]) // eslint-disable-line

    return <Autocomplete
        classes={ { root: s.autocomplete } }
        disablePortal
        value={ newCustomerRelation?.opn ? newCustomerRelation.opn : null }
        inputValue={ value ? value : '' }
        isOptionEqualToValue={ (option, value) => option.opnID === value?.opnID }
        options={ chips }
        loading={ OPNsLoading }
        onChange={ (_event: SyntheticEvent, value: ChipOPN | null) => setOPN (value ? value : undefined)}
        onInputChange={ (e, v) => setValue (v) }
        getOptionLabel={ (option) => option.opnName }
        size={ 'small' }
        renderInput={ (params) => <TextField { ...params } label="Add part number"/> }
    />
}