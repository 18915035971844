import Storage from "./config";

export interface User {
    userID: number
    email: string
    nickname: string
    suspended: boolean
    firstname: string | null
    lastname: string | null
    avatarID: number | null
    avatarImage: string | null
    userRoleID: number | null
    roleName: string | null
    created: string
}

const USER = Storage.LS_PREFIX + 'User'
const TOKEN = Storage.LS_PREFIX + 'Token'

class UserStorage {
    static getUser () : User | null {
        const s = localStorage.getItem(USER)
        if (s !== null) return JSON.parse(s) as User
        return null
    }

    static setUser (user: User) {
        localStorage.setItem(USER, JSON.stringify(user))
    }

    static removeUser () {
        localStorage.removeItem(USER)
    }

    static getToken () : string | null {
        return localStorage.getItem(TOKEN)
    }

    static setToken (token: string) {
        localStorage.setItem(TOKEN, token)
    }

    static removeToken () {
        localStorage.removeItem(TOKEN)
    }
}

export default UserStorage