import React, {useEffect, useState} from 'react'

export const ContentWrapper = ({children}) => {
    const [menuHeight, setMenuHeight] = useState(0)

    useEffect(() => {
        const topMenu = document.getElementById('topBar')
        const updateHeight = () => {
            const tMHeight = topMenu?.getBoundingClientRect().height
            if (tMHeight) setMenuHeight(tMHeight)
        }
        updateHeight()
        let resizeObserver = null;
        if (topMenu) {
            resizeObserver = new ResizeObserver(updateHeight)
            resizeObserver.observe(topMenu)
        }
        window.addEventListener('resize', updateHeight)

        return () => {
            if (resizeObserver) resizeObserver.disconnect()
            window.removeEventListener('resize', updateHeight)
        }
    }, [])

    return <div style={{minHeight: `calc(100vh - ${menuHeight}px)`, overflow: 'hidden'}}>
        {children}
    </div>
}