import React, { FC, useEffect } from 'react'
import { Table } from "../../ui_components/table/Table";
import { Header } from "../../ui_components/table/Header";
import { TD, TH } from "../customers/TableComponents";
import { useChipManage } from "../../api/hooks/useChipManage";
import { OPNRowContextMenu } from "./OPNRowContextMenu";

export const OPNsTable: FC = () => {
    const { chips, getFullOPNList } = useChipManage ()

    useEffect(() => {
        getFullOPNList()
    }, []) // eslint-disable-line

    return <div>
        <Table>
            <Header>
                <TH>ID</TH>
                <TH>OPN name</TH>
                <TH>DIE</TH>
                <TH>Wafer</TH>
                <TH>Family</TH>
                <TH>Category</TH>
                <TH>Line</TH>
            </Header>
            <tbody>
                { chips.map((opn, oIndex) => <OPNRowContextMenu chipOPN={opn} ID={`${opn.opnID}-i${oIndex}`} key={oIndex}>
                    <TD>{ opn.opnID }</TD>
                    <TD>{ opn.opnName }</TD>
                    <TD>{ opn.dieName }</TD>
                    <TD>{ opn.waferName }</TD>
                    <TD>{ opn.familyName }</TD>
                    <TD>{ opn.chipCategoryName }</TD>
                    <TD>{ opn.lineName }</TD>
                </OPNRowContextMenu>) }
            </tbody>
        </Table>
    </div>
}