import React, {FC} from 'react'
import {TopBar} from "./top_bar/TopBar";
import s from './UIWrapper.module.css'
import {ContentWrapper} from "./ContentWrapper";

interface UIWrapperProps{
    children?: React.ReactNode
}

export const UIWrapper: FC<UIWrapperProps> = ({children}) => <div className={s.root}>
    <TopBar/>
    <ContentWrapper>
        {children}
    </ContentWrapper>
</div>