import React, { FC } from 'react'
import { TH } from "../customers/TableComponents";

interface PrelimQuarterProps {
    title: string
}

export const PrelimQuarter: FC<PrelimQuarterProps> = ({ title }) => <TH>
    <div>{ title }</div>
    <div>
        <div>Qty, k</div>
        <div>min Rev</div>
        <div>max Rev</div>
    </div>
</TH>