import { ChipOPNView } from "../../types/chipTypes";
import { Action } from "../actions";
import { ActionType } from "../actionTypes";

export const opnAdminReducer = (state: ChipOPNView[] = [], action: Action) => {
    switch (action.type) {
        case ActionType.SetOPNFullList:
            return action.payload
        case ActionType.AddOPNToListFirst:
            return [ action.payload, ...state ]
        case ActionType.RemoveOPNFromAdmin:
            let index = state.findIndex(opn => opn.opnID === action.payload.opnID)
            return index > -1 ? [
                    ...state.slice (0, index),
                    ...state.slice (index + 1)
                ]
             : state
        default:
            return state
    }
}