import {Theme, ThemeId} from "./themeId";

export const lightTheme : Theme = {
    name: 'Light Theme',
    id: ThemeId.LightTheme,
    options: {
        palette: {
            primary: {
                main: '#5E5CE6',
            },
            secondary: {
                main: '#82D2FF',
            },
            error: {
                main: '#FF4672',
            },
            background: {
                default: '#FFFFFF',
                paper: '#E7E4F5',
            },
            text: {
                secondary: 'rgba(17, 38, 60, 0.3)',
                disabled: 'rgba(17,38,60,0.6)',
                primary: '#11263C',
            },
        },
    }
}