import React, {FC} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import * as actionCreators from "../../api/redux/actionCreators";
import IconButton from "@mui/material/IconButton";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import s from './ThemeSwitcher.module.css'
import {StoreState} from "../../api/redux/store";
import {ThemeId} from "../../api/themes/themeId";

interface ThemeSwitcherProps {
    title?: string
}

export const ThemeSwitcher: FC<ThemeSwitcherProps> = ({title = 'Untitled'}) => {
    const dispatch = useDispatch();
    const {switchTheme} = bindActionCreators(actionCreators, dispatch)
    const themeOptions = useSelector((state: StoreState) => state.theme)

    return <div className={s.root}>
        <IconButton onClick={() => {
            if (themeOptions.id === ThemeId.DarkTheme) switchTheme(ThemeId.LightTheme)
            else switchTheme(ThemeId.DarkTheme)
        }}
                    color={'inherit'}
                    aria-label="switch-theme"
        >
            {themeOptions.id === ThemeId.DarkTheme ? <WbSunnyIcon color={"inherit"}/> :
                <NightsStayIcon/>}
        </IconButton>
    </div>
}