import { Customer, CustomersChipView } from "../types/customer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { useCallback } from "react";
import Loaders from "../redux/reducers/loadersReducer";
import axios from "axios";
import Settings from "../settings";
import FormData from "form-data";
import { ApiResponse } from "../http_connects/types";
import { ActionType } from "../redux/actionTypes";
import { showError } from "../redux/reducers/apiErrorReducer";
import { NewCustomerRelation } from "../redux/reducers/customersReducer";
import { ChipOPN } from "../types/chipTypes";
import { useAuthorisation } from "./useAuthorisation";

interface GetCustomersResponse extends ApiResponse {
    customers?: Customer[]
}

interface GetCustomersRelationsResponse extends ApiResponse {
    customersRelations?: CustomersChipView[]
}

interface GetCustomerRelationResponse extends ApiResponse {
    customerRelation?: CustomersChipView
}

interface AddCustomerRelationResponse extends ApiResponse {
    customersChip?: CustomersChipView
    customer?: Customer
}

interface iUseCustomers {
    customers: Customer[]
    loadCustomers: (ownOnly?: boolean) => void
    newCustomerRelation: NewCustomerRelation | undefined
    setCustomer: (customer: Customer | null) => void
    setOPN: (opn: ChipOPN | undefined) => void
    setComment: (comment: string | null) => void
    getCustomersRelations: () => void
    getCustomerRelation: (customerID: number, opnID: number) => void
    addCustomerRelation: () => void
    customersRelations: CustomersChipView[]
    customerRelation?: CustomersChipView
    clearCustomerRelation: () => void
}

export const useCustomers = (): iUseCustomers => {
    const { token, user } = useAuthorisation ()
    const dispatch = useDispatch ()
    const { customers, newCustomerRelation, customersRelations, customerRelation }= useSelector ((state: StoreState) => state.customers)

    const loadCustomers = useCallback ((ownOnly?: boolean) => {
        dispatch (Loaders.setForecastsLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetCustomers')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        if (ownOnly) data.append ('ownOnly', ownOnly)

        axios.post<GetCustomersResponse> (Settings.API_HOST, data)
            .then (response => {
                const { customers, error, success } = response.data
                dispatch (Loaders.setForecastsLoading (false))
                if (success) dispatch ({ type: ActionType.SetCustomers, payload: customers })
                if (error && error.code !== 16) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const setCustomer = useCallback ((customer: Customer | null) => {
        dispatch ({ type: ActionType.SetCustomerToRelation, payload: customer ? customer : undefined })
    }, [ dispatch ])

    const setComment = useCallback ((comment: string | null) => {
        dispatch ({ type: ActionType.SetCommentToRelation, payload: comment ? comment : undefined })
    }, [ dispatch ])

    const setOPN = useCallback ((opn: ChipOPN | undefined) => {
        dispatch ({ type: ActionType.SetOPNToRelation, payload: opn })
    }, [ dispatch ])

    const getCustomersRelations = useCallback (() => {
        dispatch (Loaders.setCustomersRelationsLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetCustomersRelations')
        data.append ('userID', user?.userID)
        data.append ('token', token)

        axios.post<GetCustomersRelationsResponse> (Settings.API_HOST, data)
            .then (response => {
                const { customersRelations, error, success } = response.data
                dispatch (Loaders.setCustomersRelationsLoading (false))
                if (success) dispatch ({ type: ActionType.SetCustomersRelations, payload: customersRelations })
                if (error && error.code !== 24) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const getCustomerRelation = useCallback((customerID: number, opnID: number) => {
        dispatch (Loaders.setCustomerRelationLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetCustomerRelation')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('customerID', customerID)
        data.append ('opnID', opnID)

        axios.post<GetCustomerRelationResponse> (Settings.API_HOST, data)
            .then (response => {
                const { customerRelation, error, success } = response.data
                dispatch (Loaders.setCustomerRelationLoading (false))
                if (success) dispatch ({ type: ActionType.SetCustomerRelation, payload: customerRelation })
                if (error) dispatch (showError (error))
            })
    }, [dispatch, token, user?.userID])

    const addCustomerRelation = useCallback (() => {
        dispatch (Loaders.setCreatingCustomerRelationLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'AddCustomerRelation')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('customerID', newCustomerRelation?.customer?.customerID)
        data.append ('opnID', newCustomerRelation?.opn?.opnID)
        if (newCustomerRelation?.comment) data.append('comment', newCustomerRelation.comment)

        axios.post<AddCustomerRelationResponse> (Settings.API_HOST, data)
            .then (response => {
                const { customersChip, customer, error, success } = response.data
                dispatch (Loaders.setCreatingCustomerRelationLoading (false))
                if (success) {
                    dispatch ({ type: ActionType.AddFirstCustomersRelation, payload: customersChip })
                    dispatch ({ type: ActionType.ResetNewCustomerRelation })
                    if (customer) dispatch ({ type: ActionType.ReplaceCustomer, payload: customer })
                }
                if (error && error.code !== 24) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID, newCustomerRelation ])

    const clearCustomerRelation = useCallback(() => {
        dispatch ({ type: ActionType.SetCustomerRelation, payload: undefined })
    }, [ dispatch ])

    return {
        customers,
        loadCustomers,
        newCustomerRelation,
        setCustomer,
        setComment,
        setOPN,
        getCustomersRelations,
        getCustomerRelation,
        addCustomerRelation,
        customersRelations,
        customerRelation,
        clearCustomerRelation
    }
}