import { ChipOPN } from "../types/chipTypes";
import { useAuthorisation } from "./useAuthorisation";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { useCallback } from "react";
import Loaders from "../redux/reducers/loadersReducer";
import axios from "axios";
import Settings from "../settings";
import FormData from "form-data";
import { ApiResponse } from "../http_connects/types";
import { ActionType } from "../redux/actionTypes";
import { showError } from "../redux/reducers/apiErrorReducer";

interface iUseChips {
    chips: ChipOPN[]
    loadOPNs: (search?: string, customerID?: number) => void
}

interface GetOPNsResponse extends ApiResponse {
    opns?: ChipOPN[]
}

export const useChips = (): iUseChips => {
    const { token, user } = useAuthorisation ()
    const dispatch = useDispatch ()

    const chips = useSelector ((state: StoreState) => state.chipOPNs)

    const loadOPNs = useCallback ((search?: string, customerID?: number) => {

        dispatch (Loaders.setOPNsLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetOPNs')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        if (search) data.append ('search', encodeURIComponent (search))
        if (customerID) data.append ('customerID', customerID)

        axios.post<GetOPNsResponse> (Settings.API_HOST, data)
            .then (response => {
                dispatch (Loaders.setOPNsLoading (false))
                const { success, error, opns } = response.data
                if (success) dispatch ({ type: ActionType.SetOPNs, payload: opns })
                if (error && error.code === 19)
                    dispatch ({ type: ActionType.SetOPNs, payload: [] })
                else if (error) dispatch (showError (error))
            })

    }, [ dispatch, token, user?.userID ])

    return { chips, loadOPNs }
}