export interface Route {
    path: string
    name: string
    icon?: string
}

interface Routes {
    [index: string] : Route
}

export const routes : Routes = {
    dashboard :  {
        name: 'dashboard',
        path: '/'
    },
    customers: {
        name: 'customers',
        path: '/customers'
    },
    forecast: {
        name: 'forecast',
        path: '/forecast'
    },
    prelimAllocation: {
        name: 'prelim_allocation',
        path: '/prelim_allocation'
    },
    finalAllocation: {
        name: 'final_allocation',
        path: '/final_allocation'
    },
    prelimWaferPlanning: {
        name: 'prelim_wafer_planning',
        path: '/prelim_wafer_planning'
    },
    waferPlanning: {
        name: 'wafer_planning',
        path: '/wafer_planning'
    },
    opnManage: {
        name: 'opn_manage',
        path: '/opn/manage'
    },
    customersManage: {
        name: 'customers_manage',
        path: '/customers/manage'
    }
}