import { Forecast } from "../../types/forecast";
import { Action } from "../actions";
import { ActionType } from "../actionTypes";

export const forecastsReducer = (state : Array<Forecast> = [], action : Action) => {
    switch (action.type) {
        case ActionType.AppendForecasts:
            return state.concat(action.payload)
        case ActionType.ClearForecasts:
            return []
        case ActionType.AddForecastFirst:
            return [action.payload].concat(state)
        case ActionType.RemoveForecast:
            let index = state.indexOf(action.payload)
            return [...state.slice(0,index), ...state.slice(index + 1)]
        default:
            return state
    }
}

export const clearForecasts = () => {
    return { type: ActionType.ClearForecasts }
}