import React, { FC, useEffect, useState } from 'react'
import { PrelimPlanning } from "../../../api/types/prelimPlanning";
import { useSelector } from "react-redux";
import { StoreState } from "../../../api/redux/store";
import Settings from "../../../api/settings";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import s from './PrelimAllocationEditingDialog.module.css'
import { iPrelimQuarter, rearrangePrelimQuartersBySample } from "../prelimFuncs";
import { PrelimQuarterFields } from "./PrelimQuarterFields";
import { usePrelims } from "../../../api/hooks/usePrelims";

interface PrelimAllocationEditingDialogProps {
    prelimPlanning: PrelimPlanning
    samplePrelimPlanning: PrelimPlanning
    close: () => void
}

export const PrelimAllocationEditingDialog:
    FC<PrelimAllocationEditingDialogProps> = ({
                                                  prelimPlanning,
                                                  samplePrelimPlanning,
                                                  close
                                              }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const { updatePrelimPlanning } = usePrelims()
    const [ opened, setOpened ] = useState (true)
    const [ quarters, setQuarters ] = useState<iPrelimQuarter[]> ([])

    const pendingClose = () => {
        setOpened (false);
        setTimeout (() => close (), 500)
    }

    useEffect(() => {
        setQuarters(rearrangePrelimQuartersBySample(prelimPlanning, samplePrelimPlanning))
    }, []) // eslint-disable-line

    const hasDifference = () => {
        let has = false
        quarters.forEach((quarter, index) => {
            switch(index) {
                case 0:
                    prelimPlanning?.quarter1QTYk !== quarter.QTYk && (has = true)
                    prelimPlanning?.quarter1minRev !== quarter.minRev && (has = true)
                    prelimPlanning?.quarter1maxRev !== quarter.maxRev && (has = true)
                    break
                case 1:
                    prelimPlanning?.quarter2QTYk !== quarter.QTYk && (has = true)
                    prelimPlanning?.quarter2minRev !== quarter.minRev && (has = true)
                    prelimPlanning?.quarter2maxRev !== quarter.maxRev && (has = true)
                    break
                case 2:
                    prelimPlanning?.quarter3QTYk !== quarter.QTYk && (has = true)
                    prelimPlanning?.quarter3minRev !== quarter.minRev && (has = true)
                    prelimPlanning?.quarter3maxRev !== quarter.maxRev && (has = true)
                    break
                case 3:
                    prelimPlanning?.quarter4QTYk !== quarter.QTYk && (has = true)
                    prelimPlanning?.quarter4minRev !== quarter.minRev && (has = true)
                    prelimPlanning?.quarter4maxRev !== quarter.maxRev && (has = true)
                    break
            }
        })
        return has
    }

    const hasErrors = () => {
        let has = false
        quarters.forEach(q => ((q.minRev !== null && !q.minRev.match(/^(\d)+([.]\d+)?$/)) || (q.maxRev !== null && !q.maxRev.match(/^(\d)+([.]\d+)?$/))) && (has = true))
        return has
    }


    const preparePrelim = () : PrelimPlanning => {
        return {
            ...samplePrelimPlanning,
            dieID: prelimPlanning.dieID,
            districtID: prelimPlanning.districtID,
            quarter1QTYk: quarters[0].QTYk,
            quarter1minRev: quarters[0].minRev,
            quarter1maxRev: quarters[0].maxRev,
            quarter2QTYk: quarters[1].QTYk,
            quarter2minRev: quarters[1].minRev,
            quarter2maxRev: quarters[1].maxRev,
            quarter3QTYk: quarters[2].QTYk,
            quarter3minRev: quarters[2].minRev,
            quarter3maxRev: quarters[2].maxRev,
            quarter4QTYk: quarters[3].QTYk,
            quarter4minRev: quarters[3].minRev,
            quarter4maxRev: quarters[3].maxRev,
        }
    }

    return <Dialog
        maxWidth={ 'sm' }
        fullWidth
        open={ opened }
        transitionDuration={ Settings.FADE_DURATION }
        onClose={ pendingClose }
    >
        <DialogTitle>
            { lang.pages.prelimAllocation.editPrelimAllocationDialog.title.toUpperCase () }
        </DialogTitle>
        <DialogContent className={ s.content }>
            {quarters.map((quarter, qIndex) =>
                <PrelimQuarterFields
                    key={qIndex}
                    onQTYkChanged={QTYk => {
                        let quartersCopy = [...quarters]
                        quartersCopy[qIndex] = { ...quartersCopy[qIndex], QTYk }
                        setQuarters( quartersCopy );
                    }}
                    onMinRevChanged={minRev => {
                        let quartersCopy = [...quarters]
                        quartersCopy[qIndex] = { ...quartersCopy[qIndex], minRev }
                        setQuarters( quartersCopy );
                    }}
                    onMaxRevChanged={maxRev => {
                        let quartersCopy = [...quarters]
                        quartersCopy[qIndex] = { ...quartersCopy[qIndex], maxRev }
                        setQuarters( quartersCopy );
                    }}
                    quarter={quarter}/>)}
        </DialogContent>
        <DialogActions>
            <Button onClick={ pendingClose } color={ 'secondary' }>
                { lang.ui.commons.cancelBTN }
            </Button>
            <Button color={ 'primary' } disabled={ !hasDifference() || hasErrors() } onClick={ () => {
                let prelim = preparePrelim()
                updatePrelimPlanning(prelim)
                pendingClose ()
            } }>
                { lang.ui.commons.applyBTN }
            </Button>
        </DialogActions>
    </Dialog>
}