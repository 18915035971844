import React, { FC, useEffect } from 'react'
import { BUSegmentApplication } from "../../../api/types/commons";
import { Autocomplete, TextField } from "@mui/material";
import { useCustomersManage } from "../../../api/hooks/useCustomersManage";

interface BUApplicationChooserProps {
    selectedBUApplication: BUSegmentApplication | null
    onSelectedBUApplication: (newBUApplication: BUSegmentApplication|null) => void
}

export const BUApplicationChooser: FC<BUApplicationChooserProps> = ({ selectedBUApplication, onSelectedBUApplication }) => {
    const { BUApplications, getBUApplications } = useCustomersManage ()

    useEffect (() => {
        getBUApplications ()
    }, [ getBUApplications ])

    useEffect(() => {
    }, [selectedBUApplication])

    return <Autocomplete
        disablePortal
        renderInput={ params => <TextField variant={ 'standard' } { ...params }/> }
        options={ BUApplications }
        size={ 'small' }
        value={ selectedBUApplication}
        onChange={ (e, buApp: BUSegmentApplication|null) => onSelectedBUApplication (buApp) }
        getOptionLabel={ (buApp: BUSegmentApplication) => buApp.BUApplicationName }
        isOptionEqualToValue={ (buApp: BUSegmentApplication, value: BUSegmentApplication) => buApp.BUSegmentApplicationsID === value.BUSegmentApplicationsID }
    />
}