import React, { FC, useEffect } from 'react'
import { Customer } from "../../../api/types/customer";
import { useCustomers } from "../../../api/hooks/useCustomers";
import { Autocomplete, Box, TextField } from "@mui/material";
import s from './AddForecastDialog.module.css'

interface CustomersAutoListProps {
    onSelected: (customer: Customer) => void
    customer?: Customer
}

export const CustomersAutoList: FC<CustomersAutoListProps> = ({ onSelected, customer }) => {
    const { loadCustomers, customers } = useCustomers()

    useEffect(() => {
        loadCustomers(true)
    }, []) // eslint-disable-line

    return <Autocomplete
        classes={{root: s.autocomplete}}
        autoSelect
        value={customer}
        fullWidth={true}
        isOptionEqualToValue={(option, value) => option.customerID === value.customerID}
        options={ customers }
        disableClearable={true}
        onChange={(_event, newCustomer: Customer) => onSelected(newCustomer)}
        getOptionLabel={(option) => option.customerName}
        renderOption={(props, option) => (
            <Box component="li" {...props}>
                <span style={{fontWeight: option.userID !== null ? 700 : 300}}>{option.customerName}</span>
            </Box>
        )}
        size={'small'}
        renderInput={ (params) => <TextField { ...params } label="Add customer"/> }
    />
}