import { PrelimPlanning } from "../../types/prelimPlanning";
import { Action } from "../actions";
import { ActionType } from "../actionTypes";

interface PrelimStore {
    prelimPlannings: PrelimPlanning[]
    samplePrelimPlanning?: PrelimPlanning
}

const init: PrelimStore = {
    prelimPlannings: []
}

export const prelimReducer = (state: PrelimStore = init, action: Action): PrelimStore => {
    switch (action.type) {
        case ActionType.SetSamplePrelimPlanning:
            return { ...state, samplePrelimPlanning: action.payload }
        case ActionType.SetPrelimPlannings:
            return { ...state, prelimPlannings: action.payload }
        case ActionType.UpdatePrelimPlanning:
            let index = state.prelimPlannings.findIndex ((prelimPlanning => prelimPlanning.dieID === action.payload.dieID && prelimPlanning.districtID === action.payload.districtID))
            return index > -1 ? {
                ...state, prelimPlannings: [
                    ...state.prelimPlannings.slice (0, index),
                    { ...action.payload },
                    ...state.prelimPlannings.slice (index + 1)
                ]
            } : {
                ...state, prelimPlannings: [
                    action.payload,
                    ...state.prelimPlannings
                ]
            }
        default:
            return state
    }
}