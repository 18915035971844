import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import {themeReducer} from "./reducers/themeReducer";
import {langReducer} from "./reducers/langReducer";
import { userReducer } from "./reducers/userReducer";
import { tokenReducer } from "./reducers/tokenReducer";
import { loadersReducer } from "./reducers/loadersReducer";
import { apiErrorReducer } from "./reducers/apiErrorReducer";
import { forecastsReducer } from "./reducers/forecastsReducer";
import { customersReducer } from './reducers/customersReducer';
import { chipOPNReducer } from "./reducers/chipOPNReducer";
import { prelimReducer } from "./reducers/prelimAllocationReducer";
import { opnAdminReducer } from "./reducers/opnAdminReducer";
import { customersAdminReducer } from "./reducers/customersAdminReducer";

const rootReducer = combineReducers({
    theme: themeReducer,
    lang: langReducer,
    user: userReducer,
    token: tokenReducer,
    loaders: loadersReducer,
    error: apiErrorReducer,
    forecasts: forecastsReducer,
    customers: customersReducer,
    chipOPNs: chipOPNReducer,
    prelim: prelimReducer,
    OPNsAdmin: opnAdminReducer,
    customersAdmin: customersAdminReducer
})

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
)

export default store

export type StoreState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch