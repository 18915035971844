import React, { FC, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Settings from "../api/settings";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../api/redux/store";
import { clearError } from "../api/redux/reducers/apiErrorReducer";
import s from './ApiErrorDialog.module.css'
import { clearUser } from "../api/redux/reducers/userReducer";
import { clearToken } from "../api/redux/reducers/tokenReducer";

const ApiErrorDialog: FC = () => {
    const dispatch = useDispatch()
    const error = useSelector((store: StoreState) => store.error)
    const lang = useSelector((store: StoreState) => store.lang)

    const close = () => dispatch(clearError())

    useEffect(() => {
        if (error?.code === 12) {
            console.error('authorization error')
            dispatch(clearUser())
            dispatch(clearToken())
        }
    }, [dispatch, error])

    return <Dialog classes={{paper: s.root}} maxWidth='xs' fullWidth open={ (Boolean(error)) } transitionDuration={ Settings.FADE_DURATION } onClick={close}>
        <DialogTitle classes={{root: s.alert}}>{ error && lang.ui.errorDialog.errorPad + error.code }</DialogTitle>
        <DialogContent>
            { error && error.messages[lang.lang] }
        </DialogContent>
        <DialogActions>
            <Button autoFocus color={'error'} variant={'contained'}>
                { lang.ui.commons.okBTN }
            </Button>
        </DialogActions>
    </Dialog>
}

export default ApiErrorDialog