import {ThemeId} from "../themes/themeId";
import Storage from "./config";

const THEME_ID = Storage.LS_PREFIX + 'ThemeID'

export class ThemeStorage {
    static getThemeId () : ThemeId | null {
        const s = localStorage.getItem(THEME_ID)
        if (s !== null) return JSON.parse(s) as ThemeId
        return null
    }

    static setThemeId (themeID: ThemeId) {
        localStorage.setItem(THEME_ID, JSON.stringify(themeID))
    }
}