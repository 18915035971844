import React, {FC} from 'react'
import {Route} from "../../api/routes";
import s from './MenuItem.module.css'
import {useSelector} from "react-redux";
import {StoreState} from "../../api/redux/store";
import {Link, useLocation} from 'react-router-dom';

interface MenuItemProps {
    route: Route
}

export const MenuItem: FC<MenuItemProps> = ({route}) => {
    const lang = useSelector((state : StoreState) => state.lang)
    const location = useLocation()
    
    const active = location.pathname === route.path

    return <div className={`${s.root}${active? (' ' + s.active) : ''}`}>
        <Link to={route.path}>{lang.routes[route.name].shortMenuName}</Link>
    </div>
}