import React, { FC } from 'react'
import s from './EditForecastDialog.module.css'
import { useSelector } from "react-redux";
import { StoreState } from "../../../api/redux/store";
import { TextField } from "@mui/material";

export interface iQuarter {
    name: string
    QTY: string | null
    DC: string | null
}

interface QuarterProps {
    quarter: iQuarter
    onQTYChanged: (qty: string|null) => void
    onDCChanged: (dc: string|null) => void
}

export const Quarter: FC<QuarterProps> = ({ quarter, onQTYChanged, onDCChanged }) => {
    const lang = useSelector ((store: StoreState) => store.lang)

    return <div className={ s.quarter }>
        { quarter.name }
        <TextField
            onChange={e => {
                if (e.target.value.match(/^\d*$/)) onQTYChanged (e.target.value === '' ? null : e.target.value)
            }}
            value={quarter.QTY !== null ? quarter.QTY : ''}
            label={ lang.pages.forecast.editForecastDialog.quarter.quantityLabel }
            size={ 'small' }
        />
        <TextField
            onChange={e => {
                let val = e.target.value.replace(',', '.')
                if (val.match(/^$|^[\d.]+$/)) onDCChanged (val === '' ? null : val)
            }}
            value={quarter.DC !== null ? quarter.DC : ''}
            error={quarter.DC !== null && !quarter.DC.match(/^(\d)+([.]\d+)?$/)}
            label={ lang.pages.forecast.editForecastDialog.quarter.distributionCostLabel }
            size={ 'small' }
        />
    </div>
}