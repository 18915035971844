import { User } from "../storage/userStorage";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/store";

interface iUseAuthorisation {
    user: User | null
    token: string | null
}

export const useAuthorisation = () : iUseAuthorisation => {
    const user = useSelector ((state: StoreState) => state.user)
    const token = useSelector ((state: StoreState) => state.token)

    return { user, token }
}