import React, { FC } from 'react'
import s from './CustomerChipRelationTable.module.css'
import { TH } from "./TableComponents";
import { CustomerChipRelationCreator } from "./customersRelationCreator/CustomerChipRelationCreator";
import { CustomerChipRelationsRows } from "./CustomerChipRelationsRows";

export const CustomerChipRelationTable: FC = () => <div className={s.root}>
    <table>
        <thead>
            <tr>
                <TH>Customer name</TH>
                <TH>Part number</TH>
                <TH>Comment</TH>
            </tr>
        </thead>
        <tbody>
            <CustomerChipRelationCreator/>
            <CustomerChipRelationsRows/>
        </tbody>
    </table>
</div>