import { User } from "../storage/userStorage";
import { useAuthorisation } from "./useAuthorisation";
import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import Loaders from "../redux/reducers/loadersReducer";
import axios from "axios";
import Settings from "../settings";
import FormData from "form-data";
import { ActionType } from "../redux/actionTypes";
import { showError } from "../redux/reducers/apiErrorReducer";
import { ApiResponse } from "../http_connects/types";
import { UserAvatar } from "../types/avatar";
import { setToken } from "../redux/reducers/tokenReducer";
import { UserRole } from "../types/userRole";

interface GetUserResponse extends ApiResponse {
    user?: User
}

interface UploadAvatarResponse extends ApiResponse {
    avatar?: UserAvatar
}

interface UpdatePasswordResponse extends ApiResponse {
    token?: string
}

interface iUseUsers {
    user: User | null
    userAccessed: (accessedRoles: UserRole[]) => boolean
    getUser: () => void
    updateUser: (user: User) => void
    uploadAvatar: (avatarImage: File) => void
    uploadedAvatar: UserAvatar | null
    updatePassword: (password: string, onDone: (success: boolean) => void) => void
}

export const useUsers = (): iUseUsers => {
    const { token, user } = useAuthorisation ()
    const dispatch = useDispatch ()
    const [ uploadedAvatar, setUploadedAvatar ] = useState<UserAvatar|null>(null)

    const getUser = useCallback (() => {
        dispatch (Loaders.setUserLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetUser')
        data.append ('userID', user?.userID)
        data.append ('token', token)

        axios.post<GetUserResponse> (Settings.API_HOST, data)
            .then (response => {
                const { user, success, error } = response.data
                dispatch (Loaders.setUserLoading (false))
                if (success) {
                    dispatch ({ type: ActionType.SetUser, payload: user })
                }
                if (error) dispatch (showError (error))
            })

    }, [ dispatch, token, user?.userID ])

    const updateUser = useCallback((userForUpdate: User) => {
        dispatch (Loaders.setUserSaving (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'UpdateUser')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('user', encodeURIComponent(JSON.stringify(userForUpdate)))

        axios.post<GetUserResponse> (Settings.API_HOST, data)
            .then (response => {
                const { user, success, error } = response.data
                dispatch (Loaders.setUserSaving (false))
                if (success) {
                    dispatch ({ type: ActionType.SetUser, payload: user })
                }
                if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const uploadAvatar = useCallback((avatarImage: File) => {
        dispatch (Loaders.setAvatarUpdating (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'UploadAvatar')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('image', avatarImage)

        axios.post<UploadAvatarResponse> (Settings.API_HOST, data)
            .then (response => {
                const { avatar, success, error } = response.data
                dispatch (Loaders.setAvatarUpdating (false))
                if (success && avatar) setUploadedAvatar(avatar)
                if (error) dispatch (showError (error))
            })
    }, [dispatch, token, user?.userID])

    const updatePassword = useCallback ((password: string, onDone: (success: boolean) => void) => {
        dispatch (Loaders.setPasswordUpdateLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'UpdatePassword')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('something', encodeURIComponent (password))

        axios.post<UpdatePasswordResponse> (Settings.API_HOST, data)
            .then (response => {
                const { token, success, error } = response.data
                onDone (success)
                dispatch (Loaders.setPasswordUpdateLoading (false))
                if (success && token) dispatch (setToken (token))
                if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const userAccessed = useCallback ((accessedRoles: UserRole[]) => {
        let accessed = false
        accessedRoles.forEach (role => {
            if (user?.userRoleID === role) accessed = true
        })
        return accessed
    }, [ user?.userRoleID ])

    return { user, userAccessed, getUser, updateUser, uploadAvatar, uploadedAvatar, updatePassword }
}