import React, { FC } from 'react'
import s from './CustomersManage.module.css'
import { CustomersTable } from "./customersTable/CustomersTable";
import { UserRole } from "../../api/types/userRole";
import { AuthWrapper } from "../../ui_components/AuthWrapper";

export const CustomersManage: FC = () => <AuthWrapper accessedRoles={ [ UserRole.Moderator ] }>
    <div className={ s.root }>
        <CustomersTable/>
    </div>
</AuthWrapper>