import React, { FC } from 'react'
import s from './CustomerChipRelationTable.module.css'

interface CellProps {
    children?: React.ReactNode
    className?: string
}

const Cell: FC<CellProps> = ({ children,  className }) => <div className={ `${s.cell}${className? (' ' + className) : ''}` }>
    { children }
</div>

interface TProps extends CellProps {
    className?: string
    fullWidth?: boolean
    cellClassName?: string
}

export const TH: FC<TProps> = ({ children, className }) => <th className={ className }><Cell>{ children }</Cell></th>

export const TD: FC<TProps> = ({ children, className, fullWidth = false, cellClassName }) => <td
    className={ `${ className }${ fullWidth ? (' ' + s.full) : "" }` }><Cell className={cellClassName}>{ children }</Cell></td>