import React, { FC, useEffect, useState } from 'react'
import { usePrelims } from "../../api/hooks/usePrelims";
import { Table } from "../../ui_components/table/Table";
import { Header } from "../../ui_components/table/Header";
import { TD, TH } from "../customers/TableComponents";
import s from './PrelimAllocation.module.css'
import { extractPrelimQuarters, getQuarterByName } from "./prelimFuncs";
import { PrelimQuarter } from "./PrelimQuarter";
import { PrelimQuarterCell } from "./PrelimQuarterCell";
import { PrelimAllocationEditingDialog } from "./prelimEditor/PrelimAllocationEditingDialog";
import { PrelimPlanning } from "../../api/types/prelimPlanning";
import { useUsers } from "../../api/hooks/useUsers";
import { UserRole } from "../../api/types/userRole";
import { AuthWrapper } from "../../ui_components/AuthWrapper";

export const PrelimAllocation: FC = () => {
    const { user, userAccessed } = useUsers ()
    const accessed = userAccessed([UserRole.Seller, UserRole.BusinessUnit])
    const { getPrelimPlannings, prelimPlannings, samplePrelimPlanning } = usePrelims ()
    const [ prelimQuarters, setPrelimQuarters ] = useState<string[]> ([])
    const [ prelimPlanningForEditing, setPrelimPlanningForEditing ] = useState<PrelimPlanning | null> (null)

    useEffect (() => {
        if (accessed) getPrelimPlannings ()
    }, [])  // eslint-disable-line

    useEffect (() => {
        if (accessed && samplePrelimPlanning) setPrelimQuarters (extractPrelimQuarters (samplePrelimPlanning))
    }, [accessed, samplePrelimPlanning])

    return <AuthWrapper accessedRoles={[UserRole.Seller, UserRole.BusinessUnit]}>
        <div className={ s.root }>
        <Table>
            <Header>
                <TH>Die</TH>
                <TH>District</TH>
                { prelimQuarters.map ((quarterName, qIndex) => <PrelimQuarter key={ qIndex } title={ quarterName }/>) }
            </Header>
            <tbody>
                { prelimPlannings.map ((prelim, pIndex) => <tr key={ pIndex } onDoubleClick={ () => {
                    user?.userRoleID === 3 && setPrelimPlanningForEditing (prelim)
                } } className={ `${ s.rowArrowCursor } ${ s.noSelect }` }>
                    <TD>{ prelim.dieName }</TD>
                    <TD>{ prelim.districtName }</TD>
                    { prelimQuarters.map ((quarterName, qIndex) => {
                        let q = getQuarterByName (quarterName, prelim)
                        return <PrelimQuarterCell key={ qIndex } prelimQuarter={ q }/>
                    }) }
                </tr>) }
            </tbody>
        </Table>
        { prelimPlanningForEditing && samplePrelimPlanning && <PrelimAllocationEditingDialog
            prelimPlanning={ prelimPlanningForEditing }
            samplePrelimPlanning={ samplePrelimPlanning }
            close={ () => setPrelimPlanningForEditing (null) }/> }
    </div>
    </AuthWrapper>
}