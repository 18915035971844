import { CustomerView } from "../../types/customer";
import { Action } from "../actions";
import { ActionType } from "../actionTypes";
import { BUSegmentApplication, Territory } from "../../types/commons";

interface CustomerStore {
    customers: CustomerView[]
    territories: Territory[]
    BUApplications: BUSegmentApplication[]
}

const init: CustomerStore = {
    customers: [],
    territories: [],
    BUApplications: []
}

export const customersAdminReducer = (state: CustomerStore = init, action: Action): CustomerStore => {
    switch (action.type) {
        case ActionType.SetCustomersAdmin:
            return { ...state, customers: action.payload }
        case ActionType.AddCustomerAdminFirst:
            return { ...state, customers: [ action.payload, ...state.customers ] }
        case ActionType.UpdateCustomerAdmin:
            let index = state.customers.findIndex(customer => customer.customerID === action.payload.customerID)
            return index !== -1 ? {
                ...state, customers: [
                    ...state.customers.slice (0, index),
                    {...action.payload},
                    ...state.customers.slice (index + 1)
                ]
            } : state
        case ActionType.SetTerritories:
            return { ...state, territories: action.payload }
        case ActionType.SetBUApplications:
            return { ...state, BUApplications: action.payload }
        default:
            return state
    }
}