import { Action } from "../actions";
import { ActionType } from "../actionTypes";

export interface iLoaders {
    loginLoading: boolean
    forecastsLoading: boolean
    customersLoading: boolean
    OPNsLoading: boolean
    customersRelationsLoading: boolean
    creatingCustomerRelationLoading: boolean
    customerRelationLoading: boolean
    addForecastLoading: boolean
    updateForecastLoading: boolean
    userLoading: boolean
    userSaving: boolean
    avatarUpdating: boolean
    prelimPlanningsLoading: boolean
    prelimPlanningUpdateLoading: boolean
    OPNFullListLoading: boolean
    linesLoading: boolean
    categoriesLoading: boolean
    familiesLoading: boolean
    wafersLoading: boolean
    diesLoading: boolean
    OPNCreateLoading: boolean
    customerAddLoading: boolean
    territoriesLoading: boolean
    BUApplicationsLoading: boolean
    passwordUpdateLoading: boolean
}

const initLoaders : iLoaders = {
    loginLoading: false,
    forecastsLoading: false,
    customersLoading: false,
    OPNsLoading: false,
    customersRelationsLoading: false,
    creatingCustomerRelationLoading: false,
    customerRelationLoading: false,
    addForecastLoading: false,
    updateForecastLoading: false,
    userLoading: false,
    userSaving: false,
    avatarUpdating: false,
    prelimPlanningsLoading: false,
    prelimPlanningUpdateLoading: false,
    OPNFullListLoading: false,
    linesLoading: false,
    categoriesLoading: false,
    familiesLoading: false,
    wafersLoading: false,
    diesLoading: false,
    OPNCreateLoading: false,
    customerAddLoading: false,
    territoriesLoading: false,
    BUApplicationsLoading: false,
    passwordUpdateLoading: false
}

export const loadersReducer = (state : iLoaders = initLoaders, action: Action) => {
    switch (action.type) {
        case ActionType.SetLoginLoading:
            return {...state, loginLoading: action.payload}
        case ActionType.SetForecastsLoading:
            return {...state, forecastsLoading: action.payload}
        case ActionType.SetCustomersLoading:
            return {...state, customersLoading: action.payload}
        case ActionType.SetOPNsLoading:
            return {...state, OPNsLoading: action.payload}
        case ActionType.SetCustomersRelationsLoading:
            return {...state, customersRelationsLoading: action.payload}
        case ActionType.SetCreatingCustomerRelationLoading:
            return {...state, creatingCustomerRelationLoading: action.payload}
        case ActionType.SetCustomerRelationLoading:
            return {...state, customerRelationLoading: action.payload}
        case ActionType.SetAddForecastLoading:
            return {...state, addForecastLoading: action.payload}
        case ActionType.SetUpdateForecastLoading:
            return {...state, updateForecastLoading: action.payload}
        case ActionType.SetUserLoading:
            return {...state, userLoading: action.payload}
        case ActionType.SetUserSaving:
            return {...state, userSaving: action.payload}
        case ActionType.SetAvatarUpdating:
            return {...state, avatarUpdating: action.payload}
        case ActionType.SetPrelimPlanningsLoading:
            return {...state, prelimPlanningsLoading: action.payload}
        case ActionType.SetPrelimPlanningUpdateLoading:
            return {...state, prelimPlanningUpdateLoading: action.payload}
        case ActionType.SetOPNFullListLoading:
            return {...state, OPNFullListLoading: action.payload}
        case ActionType.SetLinesLoading:
            return {...state, linesLoading: action.payload}
        case ActionType.SetCategoriesLoading:
            return {...state, categoriesLoading: action.payload}
        case ActionType.SetFamiliesLoading:
            return {...state, familiesLoading: action.payload}
        case ActionType.SetWafersLoading:
            return {...state, wafersLoading: action.payload}
        case ActionType.SetDiesLoading:
            return {...state, diesLoading: action.payload}
        case ActionType.SetOPNCreateLoading:
            return {...state, OPNCreateLoading: action.payload}
        case ActionType.SetCustomerAddLoading:
            return {...state, customerAddLoading: action.payload}
        case ActionType.SetTerritoriesLoading:
            return {...state, territoriesLoading: action.payload}
        case ActionType.SetBUApplicationsLoading:
            return {...state, BUApplicationsLoading: action.payload}
        case ActionType.SetPasswordUpdateLoading:
            return {...state, passwordUpdateLoading: action.payload}
        default: return state
    }
}

const setLoginLoading = (loading: boolean) => {
    return { type: ActionType.SetLoginLoading, payload: loading }
}

const setForecastsLoading = (loading: boolean) => {
    return { type: ActionType.SetForecastsLoading, payload: loading }
}

const setCustomersLoading = (loading: boolean) => {
    return { type: ActionType.SetCustomersLoading, payload: loading }
}

const setOPNsLoading = (loading: boolean) => {
    return { type: ActionType.SetOPNsLoading, payload: loading }
}

const setCustomersRelationsLoading = (loading: boolean) => {
    return { type: ActionType.SetCustomersRelationsLoading, payload: loading }
}

const setCreatingCustomerRelationLoading = (loading: boolean) => {
    return { type: ActionType.SetCreatingCustomerRelationLoading, payload: loading }
}

const setCustomerRelationLoading = (loading: boolean) => {
    return { type: ActionType.SetCustomerRelationLoading, payload: loading }
}

const setAddForecastLoading = (loading: boolean) => {
    return { type: ActionType.SetAddForecastLoading, payload: loading }
}

const setUpdateForecastLoading = (loading: boolean) => {
    return { type: ActionType.SetUpdateForecastLoading, payload: loading }
}

const setUserLoading = (loading: boolean) => {
    return { type: ActionType.SetUserLoading, payload: loading }
}

const setUserSaving = (loading: boolean) => {
    return { type: ActionType.SetUserSaving, payload: loading }
}

const setAvatarUpdating = (loading: boolean) => {
    return { type: ActionType.SetAvatarUpdating, payload: loading }
}

const setPrelimPlanningsLoading = (loading: boolean) => {
    return { type: ActionType.SetPrelimPlanningsLoading, payload: loading }
}

const setPrelimPlanningUpdateLoading = (loading: boolean) => {
    return { type: ActionType.SetPrelimPlanningUpdateLoading, payload: loading }
}

const setOPNFullListLoading = (loading: boolean) => {
    return { type: ActionType.SetOPNFullListLoading, payload: loading }
}

const setLinesLoading = (loading: boolean) => {
    return { type: ActionType.SetLinesLoading, payload: loading }
}

const setCategoriesLoading = (loading: boolean) => {
    return { type: ActionType.SetCategoriesLoading, payload: loading }
}

const setFamiliesLoading = (loading: boolean) => {
    return { type: ActionType.SetFamiliesLoading, payload: loading }
}

const setWafersLoading = (loading: boolean) => {
    return { type: ActionType.SetWafersLoading, payload: loading }
}

const setDiesLoading = (loading: boolean) => {
    return { type: ActionType.SetDiesLoading, payload: loading }
}

const setOPNCreateLoading = (loading: boolean) => {
    return { type: ActionType.SetOPNCreateLoading, payload: loading }
}

const setCustomerAddLoading = (loading: boolean) => {
    return { type: ActionType.SetCustomerAddLoading, payload: loading }
}

const setTerritoriesLoading = (loading: boolean) => {
    return { type: ActionType.SetTerritoriesLoading, payload: loading }
}

const setBUApplicationsLoading = (loading: boolean) => {
    return { type: ActionType.SetBUApplicationsLoading, payload: loading }
}

const setPasswordUpdateLoading = (loading: boolean) => {
    return { type: ActionType.SetPasswordUpdateLoading, payload: loading }
}

const Loaders = {
    setLoginLoading,
    setForecastsLoading,
    setCustomersLoading,
    setOPNsLoading,
    setCustomersRelationsLoading,
    setCreatingCustomerRelationLoading,
    setCustomerRelationLoading,
    setAddForecastLoading,
    setUpdateForecastLoading,
    setUserLoading,
    setUserSaving,
    setAvatarUpdating,
    setPrelimPlanningsLoading,
    setPrelimPlanningUpdateLoading,
    setOPNFullListLoading,
    setLinesLoading,
    setCategoriesLoading,
    setFamiliesLoading,
    setWafersLoading,
    setDiesLoading,
    setOPNCreateLoading,
    setCustomerAddLoading,
    setTerritoriesLoading,
    setBUApplicationsLoading,
    setPasswordUpdateLoading
}

export default Loaders