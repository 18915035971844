import { Action } from "../actions";
import { ActionType } from "../actionTypes";
import { ApiError } from "../../http_connects/types";

export const apiErrorReducer = (state : ApiError|null = null, action: Action) => {
    switch (action.type) {
        case ActionType.SetApiError:
            return action.payload
        case ActionType.ClearApiError:
            return null
        default: return state
    }
}

export const showError = (error: ApiError) => {
    return { type: ActionType.SetApiError, payload: error }
}

export const clearError = () => {
    return { type: ActionType.ClearApiError }
}