import React, { FC, useState } from 'react'
import AddIcon from "@mui/icons-material/AddCircle";
import { OPNCreatorDialog } from "./OPNCreatorDialog";
import s from './AddOPNButton.module.css'

export const AddOPNButton: FC = () => {
    const [ addDialogOpened, setAddDialogOpened ] = useState(false)

    return <>
        <div className={ s.root } onClick={ () => setAddDialogOpened(true) }>
            Add new OPN
            <AddIcon fontSize={ 'medium' } classes={ { root: s.icon } }/>
        </div>
        { addDialogOpened && <OPNCreatorDialog close={ () => setAddDialogOpened(false) }/> }
    </>
}