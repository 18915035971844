import {ActionType} from "./actionTypes";
import {ThemeId} from "../themes/themeId";
import {Dispatch} from "redux";
import {Action} from "./actions";

export const switchTheme = (themeName : ThemeId) => {
    return (dispatch : Dispatch<Action>) => {
        dispatch({
            type: ActionType.SwitchTheme,
            payload: themeName
        })
    }
}