import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { useCallback } from "react";
import axios from "axios";
import FormData from "form-data";
import { ApiResponse } from "../http_connects/types";
import { User } from "../storage/userStorage";
import { ActionType } from "../redux/actionTypes";
import Loaders from "../redux/reducers/loadersReducer";
import { setToken } from "../redux/reducers/tokenReducer";
import Settings from "../settings";
import { showError } from "../redux/reducers/apiErrorReducer";

interface UserResponse extends ApiResponse {
    user?: User
    token?: string
}

export const useUserLogin = () : [User|null, (email: string, password: string) => void] => {
    const user = useSelector ((state: StoreState) => state.user)
    const dispatch = useDispatch ()

    const login = useCallback (
        (email, password) => {
            dispatch(Loaders.setLoginLoading(true))

            // const controller = new AbortController();
            //
            axios.create({ ...Settings.AXIOS_CONFIG,
                // signal: controller.signal
            })

            const data = new FormData();
            data.append('action', 'Login')
            data.append('email', encodeURIComponent(email))
            data.append('something', encodeURIComponent(password))

            axios.post<UserResponse>(Settings.API_HOST, data)
                .then(response => {
                    const { user, success, error, token } = response.data
                    dispatch(Loaders.setLoginLoading(false))
                    if (success && token) {
                        dispatch({ type: ActionType.SetUser, payload: user })
                        dispatch(setToken(token))
                    }
                    if (error) dispatch(showError(error))
                })
            // return controller.abort
        },
        [ dispatch ])

    return [ user, login ]
}