import {ThemeOptions} from "@mui/material";

export enum ThemeId {
    LightTheme,
    DarkTheme
}

export interface Theme {
    id: ThemeId,
    name: string,
    options: ThemeOptions
}

