import React, { FC } from 'react'
import { TD } from "../customers/TableComponents";
import { iPrelimQuarter } from "./prelimFuncs";

interface PrelimQuarterCellProps {
    prelimQuarter?: iPrelimQuarter|null
}

export const PrelimQuarterCell: FC<PrelimQuarterCellProps> = ({ prelimQuarter }) => <TD>
    <div>
        <span>{ prelimQuarter?.QTYk ? prelimQuarter.QTYk : '' }</span>
        <span>{ prelimQuarter?.minRev ? prelimQuarter.minRev : '' }</span>
        <span>{ prelimQuarter?.maxRev ? prelimQuarter.maxRev : '' }</span>
    </div>
</TD>