import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import s from './AvatarChooser.module.css'
import { UserAvatar } from "../../api/types/avatar";
import ButtonBase from '@mui/material/ButtonBase'
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useUsers } from "../../api/hooks/useUsers";
import Settings from "../../api/settings";

interface AvatarChooserProps {
    onAvatarUpdated: (avatar: UserAvatar) => void
    disabled: boolean
}

export const AvatarChooser: FC<AvatarChooserProps> = ({ onAvatarUpdated, disabled = false }) => {
    const inputRef = useRef<HTMLInputElement> (null)
    const { user, uploadAvatar, uploadedAvatar } = useUsers ()
    const [ image, setImage ] = useState<string | null> (null)

    useEffect (() => {
        if (user) setImage (user.avatarImage ? Settings.HOST + user.avatarImage : null)
    }, [ user ])

    useEffect(() => {
        if (uploadedAvatar) {
            if (uploadedAvatar.thumbIMG) setImage(Settings.HOST + uploadedAvatar.thumbIMG)
            onAvatarUpdated (uploadedAvatar)
        }
    }, [uploadedAvatar])

    const onChooseFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) uploadAvatar(event.currentTarget.files[0])
    }

    return <>
        <input ref={ inputRef } type={ "file" } className={ s.fakeInput } onChange={ onChooseFile }
               accept={ 'image/gif, image/jpeg, image/png, image/bmp' }/>
        <ButtonBase disabled={ disabled } classes={{root: s.button}} onClick={ () => {
            inputRef?.current?.click ()
        } }>
            { image ? <div style={ { backgroundImage: `url(${ image })` } } className={ s.userAvatar }/> :
                <AccountCircleIcon className={ s.avatarIcon } color={ 'inherit' }/> }
        </ButtonBase>
    </>
}