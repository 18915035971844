import React, { FC, useState } from 'react'
import s from './AddForecastButton.module.css'
import AddIcon from '@mui/icons-material/AddCircle'
import { AddForecastDialog } from "../addForecastDialog/AddForecastDialog";

export const AddForecastButton: FC = () => {
    const [ addDialogOpened, setAddDialogOpened ] = useState(false)

    return <>
        <div className={ s.root } onClick={ () => setAddDialogOpened(true) }>
            Add Forecast
            <AddIcon fontSize={ 'medium' } classes={ { root: s.icon } }/>
        </div>
        { addDialogOpened && <AddForecastDialog close={ () => setAddDialogOpened(false) }/> }
    </>
}