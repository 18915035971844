import { CustomerView } from "../types/customer";
import { useAuthorisation } from "./useAuthorisation";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { useCallback } from "react";
import Loaders from "../redux/reducers/loadersReducer";
import axios from "axios";
import Settings from "../settings";
import FormData from "form-data";
import { ActionType } from "../redux/actionTypes";
import { showError } from "../redux/reducers/apiErrorReducer";
import { ApiResponse } from "../http_connects/types";
import { BUSegmentApplication, Territory } from "../types/commons";

interface iUseCustomersManage {
    customers: CustomerView[]
    territories: Territory[]
    BUApplications: BUSegmentApplication[]
    getBUApplications: () => void
    getTerritories: (search: string | null) => void
    getCustomers: () => void
    updateCustomer: (customer: CustomerView, onDone: (success: boolean) => void) => void
}

interface GetCustomersResponse extends ApiResponse {
    customers?: CustomerView[]
}

interface UpdateCustomerResponse extends ApiResponse {
    customer?: CustomerView
}

interface GetBUApplicationsResponse extends ApiResponse {
    BUApplications?: BUSegmentApplication[]
}

interface GetTerritoriesResponse extends ApiResponse {
    territories?: Territory[]
}

export const useCustomersManage = (): iUseCustomersManage => {
    const { token, user } = useAuthorisation ()
    const dispatch = useDispatch ()
    const { customers, BUApplications, territories } = useSelector ((state: StoreState) => state.customersAdmin)

    const getCustomers = useCallback (() => {
        dispatch (Loaders.setCustomersLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetCustomers')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('forAdmin', 1)

        axios.post<GetCustomersResponse> (Settings.API_HOST, data)
            .then (response => {
                const { customers, error, success } = response.data
                dispatch (Loaders.setCustomersLoading (false))
                if (success) dispatch ({ type: ActionType.SetCustomersAdmin, payload: customers })
                if (error && error.code !== 16) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const updateCustomer = useCallback ((customer: CustomerView, onDone: (success: boolean) => void) => {
        const isCreating = customer.customerID === null

        if (isCreating) dispatch (Loaders.setCustomerAddLoading (false))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'UpdateCustomer')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        data.append ('customer', encodeURIComponent (JSON.stringify (customer)))

        axios.post<UpdateCustomerResponse> (Settings.API_HOST, data)
            .then (response => {
                const { customer, error, success } = response.data
                if (isCreating) dispatch (Loaders.setCustomerAddLoading (false))
                onDone(success)
                if (success) {
                    if (isCreating) dispatch ({ type: ActionType.AddCustomerAdminFirst, payload: customer })
                    else dispatch ({ type: ActionType.UpdateCustomerAdmin, payload: customer })
                }
                if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const getBUApplications = useCallback (() => {
        dispatch (Loaders.setBUApplicationsLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetBUApplications')
        data.append ('userID', user?.userID)
        data.append ('token', token)

        axios.post<GetBUApplicationsResponse> (Settings.API_HOST, data)
            .then (response => {
                const { BUApplications, error, success } = response.data
                dispatch (Loaders.setBUApplicationsLoading (false))
                if (success) dispatch ({ type: ActionType.SetBUApplications, payload: BUApplications })
                if (error?.code === 73) dispatch ({ type: ActionType.SetBUApplications, payload: [] })
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    const getTerritories = useCallback ((search: string | null) => {
        dispatch (Loaders.setTerritoriesLoading (true))

        axios.create ({ ...Settings.AXIOS_CONFIG })

        const data = new FormData ();
        data.append ('action', 'GetTerritories')
        data.append ('userID', user?.userID)
        data.append ('token', token)
        if (search) data.append ('search', encodeURIComponent (search))

        axios.post<GetTerritoriesResponse> (Settings.API_HOST, data)
            .then (response => {
                const { territories, error, success } = response.data
                dispatch (Loaders.setTerritoriesLoading (false))
                if (success) dispatch ({ type: ActionType.SetTerritories, payload: territories })
                if (error?.code === 74) dispatch ({ type: ActionType.SetTerritories, payload: [] })
                else if (error) dispatch (showError (error))
            })
    }, [ dispatch, token, user?.userID ])

    return { customers, BUApplications, territories, getCustomers, updateCustomer, getBUApplications, getTerritories }
}