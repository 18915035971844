import React, { FC, useEffect } from 'react'
import s from './Forecasts.module.css'
import { useForecasts } from "../../api/hooks/useForecasts";
import { ForecastTable } from "./forecastTable/ForecastTable";
import { AddForecastButton } from "./addForecastButton/AddForecastButton";
import { UserRole } from "../../api/types/userRole";
import { AuthWrapper } from "../../ui_components/AuthWrapper";
import { useUsers } from "../../api/hooks/useUsers";

export const Forecasts: FC = () => {
    const { userAccessed } = useUsers()
    const accessed = userAccessed([UserRole.Seller, UserRole.BusinessUnit])
    const {forecasts, getForecasts} = useForecasts()

    useEffect(() => {
        if (accessed && forecasts.length === 0) getForecasts()
    }, [])  // eslint-disable-line

    return <AuthWrapper accessedRoles={[UserRole.Seller, UserRole.BusinessUnit]}>
        <div className={ s.root }>
        <AddForecastButton/>
        <ForecastTable forecasts={forecasts}/>
    </div>
    </AuthWrapper>
}