import React, { FC } from 'react'
import s from './Customers.module.css'
import { CustomerChipRelationTable } from "./CustomerChipRelationTable";
import { AuthWrapper } from "../../ui_components/AuthWrapper";
import { UserRole } from "../../api/types/userRole";

export const Customers: FC = () => {
    return <AuthWrapper accessedRoles={[UserRole.Seller]}>
        <div className={ s.root }>
            <CustomerChipRelationTable/>
        </div>
    </AuthWrapper>
}