import { Action } from "../actions";
import { ChipOPN } from "../../types/chipTypes";
import { ActionType } from "../actionTypes";

export const chipOPNReducer = (state: ChipOPN[] = [], action: Action) => {
    switch (action.type) {
        case ActionType.SetOPNs:
            return action.payload
        default:
            return state
    }
}