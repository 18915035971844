import React, {FC} from 'react'
import { useSelector } from "react-redux";
import { StoreState } from "../../api/redux/store";
import { routes } from "../../api/routes";
import { Navigate } from "react-router-dom";
import { UserRole } from "../../api/types/userRole";

export const Dashboard: FC = () => {
    const user = useSelector((state: StoreState) => state.user)
    const token = useSelector((state: StoreState) => state.token)

    return <div>
        { user && token && user.userRoleID === UserRole.Moderator && <Navigate to={routes.opnManage.path} /> }
        { user && token && user.userRoleID === UserRole.Seller && <Navigate to={routes.customers.path} /> }
        { user && token && user.userRoleID === UserRole.BusinessUnit && <Navigate to={routes.forecast.path} /> }
    </div>
}