import React, { FC, useEffect } from 'react'
import s from './CustomerChipRelationCreator.module.css'
import { Autocomplete, Box, TextField } from "@mui/material";
import { useCustomers } from "../../../api/hooks/useCustomers";
import { Customer } from "../../../api/types/customer";

export const AddCustomer: FC = () => {
    const { customers, loadCustomers, newCustomerRelation, setCustomer } = useCustomers ()

    useEffect (() => {
            loadCustomers()
    }, [])// eslint-disable-line

    return <Autocomplete
        classes={{root: s.autocomplete}}
        disablePortal
        autoSelect
        groupBy={option => {
            if (option.userID !== null) return 'Own customers'
            else return 'Not owned by anyone'
        }}
        value={newCustomerRelation?.customer? newCustomerRelation.customer : null}
        isOptionEqualToValue={(option, value) => option.customerID === value.customerID}
        options={ customers.sort((a,b) => {
            if ((a.userID === null && b.userID !== null) || (a.userID !== null && b.userID === null)) {
                return a.userID === null ? 1: -1
            } else return a.customerName > b.customerName ? 1 : -1
        }) }
        onChange={(_event, newCustomer: Customer | null) => setCustomer(newCustomer)}
        getOptionLabel={(option) => option.customerName}
        renderOption={(props, option) => (
            <Box component="li" {...props}>
                <span style={{fontWeight: option.userID !== null ? 700 : 300}}>{option.customerName}</span>
            </Box>
        )}
        size={'small'}
        renderInput={ (params) => <TextField { ...params } label="Add customer"/> }
    />
}