import { PrelimPlanning } from "../../api/types/prelimPlanning";

export interface iPrelimQuarter {
    name: string
    QTYk: string | null
    minRev: string | null
    maxRev: string | null
}

export const extractPrelimQuarters = (prelim: PrelimPlanning) : string[] => {
    const quarters: string[] = []
    if (prelim.quarter1 !== null) quarters.push (prelim.quarter1)
    if (prelim.quarter2 !== null) quarters.push (prelim.quarter2)
    if (prelim.quarter3 !== null) quarters.push (prelim.quarter3)
    if (prelim.quarter4 !== null) quarters.push (prelim.quarter4)
    return quarters
}

export const getQuarterByName = (quarterName: string, prelim: PrelimPlanning): iPrelimQuarter | null => {
    if (prelim.quarter1 === quarterName) return {
        name: quarterName,
        QTYk: prelim.quarter1QTYk,
        minRev: prelim.quarter1minRev,
        maxRev: prelim.quarter1maxRev
    }
    else if (prelim.quarter2 === quarterName) return {
        name: quarterName,
        QTYk: prelim.quarter2QTYk,
        minRev: prelim.quarter2minRev,
        maxRev: prelim.quarter2maxRev
    }
    else if (prelim.quarter3 === quarterName) return {
        name: quarterName,
        QTYk: prelim.quarter3QTYk,
        minRev: prelim.quarter3minRev,
        maxRev: prelim.quarter3maxRev
    }
    else if (prelim.quarter4 === quarterName) return {
        name: quarterName,
        QTYk: prelim.quarter4QTYk,
        minRev: prelim.quarter4minRev,
        maxRev: prelim.quarter4maxRev
    }
    else return null
}

export const rearrangePrelimQuartersBySample = (prelim: PrelimPlanning, samplePrelim: PrelimPlanning) : iPrelimQuarter[] => {
    let quarters: iPrelimQuarter[] = [];
    let initQuarter : iPrelimQuarter = {
        name: '',
        QTYk: null,
        minRev: null,
        maxRev: null
    }
    if (samplePrelim.quarter1) {
        let q = getQuarterByName(samplePrelim.quarter1, prelim)
        quarters.push(q ? q : {...initQuarter, name: samplePrelim.quarter1})
    }
    if (samplePrelim.quarter2) {
        let q = getQuarterByName(samplePrelim.quarter2, prelim)
        quarters.push(q ? q : {...initQuarter, name: samplePrelim.quarter2})
    }
    if (samplePrelim.quarter3) {
        let q = getQuarterByName(samplePrelim.quarter3, prelim)
        quarters.push(q ? q : {...initQuarter, name: samplePrelim.quarter3})
    }
    if (samplePrelim.quarter4) {
        let q = getQuarterByName(samplePrelim.quarter4, prelim)
        quarters.push(q ? q : {...initQuarter, name: samplePrelim.quarter4})
    }
    return quarters
}