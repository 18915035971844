import React, { FC, useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { StoreState } from "../../api/redux/store";
import { useUsers } from "../../api/hooks/useUsers";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Settings from "../../api/settings";
import s from './ProfileEditorDialog.module.css'
import { useLoaders } from "../../api/hooks/useLoaders";
import { AvatarChooser } from "./AvatarChooser";

interface profileEditorDialogProps {
    close: () => void
}

enum PasswordError {
    NotEqual,
    EmptySecondPassword
}

export const ProfileEditorDialog: FC<profileEditorDialogProps> = ({ close }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const [ opened, setOpened ] = useState (true)
    const { user, getUser, updateUser, updatePassword } = useUsers ()
    const { userSaving, userLoading, passwordUpdateLoading } = useLoaders ()

    const [ firstName, setFirstName ] = useState<string> ('')
    const [ lastName, setLastName ] = useState<string> ('')
    const [ avatarID, setAvatarID ] = useState<number|null> (null)

    const [ password, setPassword ] = useState<string>('')
    const [ passwordRetype, setPasswordRetype ] = useState<string>('')

    const [ error, setError ] = useState<PasswordError|null>(null)

    const pendingClose = () => {
        setOpened (false);
        setTimeout (() => close (), 500)
    }

    useEffect (() => {
        getUser ()
    }, []) // eslint-disable-line

    useEffect (() => {
        if (user) {
            setFirstName (user.firstname ? user.firstname : '')
            setLastName (user.lastname ? user.lastname : '')
            setAvatarID(user.avatarID)
        }
    }, [ user ])

    useEffect (() => {
        if (password !== '' && passwordRetype === '') setError (PasswordError.EmptySecondPassword)
        else if (password !== '' && password !== passwordRetype) setError (PasswordError.NotEqual)
        else setError (null)
    }, [ password, passwordRetype ])

    const hasDifference = (): boolean => {
        let originalFirstname = user?.firstname ? user.firstname : ''
        let originalLastname = user?.lastname ? user.lastname : ''
        return originalFirstname !== firstName || originalLastname !== lastName || user?.avatarID !== avatarID
    }

    let hasDiff = hasDifference()

    return <Dialog
        maxWidth={ 'xs' }
        fullWidth
        open={ opened }
        transitionDuration={ Settings.FADE_DURATION }
        onClose={ pendingClose }
    >
        <DialogTitle>
            { lang.ui.profileEditorDialog.title.toUpperCase () }
        </DialogTitle>
        <DialogContent className={ s.content }>
            <div className={ s.topBlock }>
                <AvatarChooser disabled={ userLoading || userSaving } onAvatarUpdated={ (avatar) => {
                    setAvatarID(avatar.avatarID)
                } }/>
                <div className={ s.textBlock }>
                    <TextField
                        value={ firstName }
                        onChange={ e => setFirstName (e.target.value) }
                        disabled={ userLoading || userSaving }
                        size={ 'small' }
                        label={ lang.ui.profileEditorDialog.firstnameLBL }
                    />
                    <TextField
                        value={ lastName }
                        onChange={ e => setLastName (e.target.value) }
                        disabled={ userLoading || userSaving }
                        size={ 'small' }
                        label={ lang.ui.profileEditorDialog.lastnameLBL }
                    />
                </div>
            </div>
            <div className={s.twoFieldsRow}>
                <TextField
                    size={'small'}
                    type={'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    label={ lang.ui.profileEditorDialog.changePasswordLBL }
                    error={error !== null}
                />
                <TextField
                    size={'small'}
                    type={'password'}
                    value={passwordRetype}
                    onChange={e => setPasswordRetype(e.target.value)}
                    label={ lang.ui.profileEditorDialog.retypePasswordLBL }
                    helperText={error === PasswordError.NotEqual ? lang.ui.profileEditorDialog.passwordsNotEqual : error === PasswordError.EmptySecondPassword ? lang.ui.profileEditorDialog.passwordEmptySecondField : undefined }
                    error={error !== null}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={ pendingClose } color={ 'secondary' }>
                { lang.ui.commons.cancelBTN }
            </Button>
            <Button color={ 'primary' } disabled={ userLoading || userSaving || passwordUpdateLoading || (!hasDiff && password === '') || (password !== '' && error !== null) } onClick={ () => {
                if (hasDiff && user) updateUser ({ ...user, firstname: firstName, lastname: lastName, avatarID })
                if (password !== '') updatePassword(password, success => {
                    if (success) {
                        setPassword('')
                        setPasswordRetype('')
                    }
                })
            } }>
                { lang.ui.commons.applyBTN }
            </Button>
            <Button color={ 'primary' } disabled={ userLoading || userSaving || passwordUpdateLoading || (!hasDiff && password === '') || (password !== '' && error !== null) } onClick={ () => {
                if (hasDiff && user) updateUser ({ ...user, firstname: firstName, lastname: lastName, avatarID })
                if (password !== '') updatePassword(password, success => {
                    if (success) {
                        setPassword('')
                        setPasswordRetype('')
                    }
                })
                pendingClose ()
            } }>
                { lang.ui.commons.applyAndClose }
            </Button>
        </DialogActions>
    </Dialog>
}