import React, { FC, useState } from 'react'
import s from './UserMenu.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useUserLogin } from "../../api/hooks/useUserLogin";
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Settings from "../../api/settings";
import Popover from '@mui/material/Popover';
import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useSelector } from "react-redux";
import { StoreState } from "../../api/redux/store";
import LogoutIcon from '@mui/icons-material/Logout';
import { LogOutDialog } from "./LogOutDialog";
import { ProfileEditorDialog } from "../userEditing/ProfileEditorDialog";

export const UserMenu: FC = () => {
    const [ user ] = useUserLogin ()
    const lang = useSelector ((store: StoreState) => store.lang)
    const [ menuAnchorEl, setMenuAnchorEl ] = useState <HTMLDivElement | null> (null)
    const [ logOutOpened, setLogOutOpened ] = useState<boolean>(false)
    const [ profileEditOpened, setProfileEditOpened ] = useState<boolean>(false)

    const onMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setMenuAnchorEl (event.currentTarget)
    };

    const onMenuClose = () => {
        setMenuAnchorEl (null)
    }

    return <div className={ s.root }>
        { user && <div>{ `${ user.firstname } ${ user.lastname }` }</div> }
        <div onClick={ onMenuClick } className={ s.menu }>
            { user?.avatarImage ? <div className={ s.avatar }
                                       style={ { backgroundImage: `url(${ Settings.HOST + user.avatarImage })` } }/>
                :
                <div className={ s.avatar }>
                    <AccountCircleIcon fontSize={ 'large' }/>
                </div> }
            <KeyboardArrowDownIcon fontSize={ "small" }/>

        </div>
        <Popover
            classes={ { paper: s.menuPopup } }
            open={ Boolean (menuAnchorEl) }
            onClose={ onMenuClose }
            anchorEl={ menuAnchorEl }
            anchorOrigin={ {
                vertical: 'top',
                horizontal: 'right',
            } }
            transformOrigin={ {
                vertical: 'top',
                horizontal: 'right',
            } }
        >
            <div className={ s.root }>
                { user && <div>{ `${ user.firstname } ${ user.lastname }` }</div> }
                <div onClick={ onMenuClose } className={ s.menu }>
                    { user?.avatarImage ? <div className={ s.avatar }
                                               style={ { backgroundImage: `url(${ Settings.HOST + user.avatarImage })` } }/>
                        :
                        <div className={ s.avatar }>
                            <AccountCircleIcon fontSize={ 'large' }/>
                        </div> }
                    <KeyboardArrowDownIcon fontSize={ "small" }/>
                </div>
            </div>
            <MenuList classes={ { root: s.menuList } }>
                <MenuItem>
                    <ListItemIcon>
                        <AssignmentIndIcon classes={ { root: s.oppositeColor } } fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText onClick={() => {
                        onMenuClose()
                        setProfileEditOpened (true)
                    }}>{ lang.ui.userMenu.editProfile }</ListItemText>
                </MenuItem>
                <Divider classes={ { root: s.opposite30BorderColor } }/>
                <MenuItem onClick={() => {
                    onMenuClose()
                    setLogOutOpened(true)
                }}>
                    <ListItemIcon>
                        <LogoutIcon classes={ { root: s.oppositeColor } } fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>{ lang.ui.userMenu.logOut }</ListItemText>
                </MenuItem>
            </MenuList>
        </Popover>
        { logOutOpened && <LogOutDialog close={() => {
            setLogOutOpened (false)
        }}/> }
        {profileEditOpened && <ProfileEditorDialog close={() => setProfileEditOpened(false)}/>}
    </div>
}