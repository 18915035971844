import React, { FC, useState } from 'react'
import { useSelector } from "react-redux";
import { StoreState } from "../api/redux/store";
import Settings from "../api/settings";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

interface ConfirmationDialogProps {
    close: () => void
    message: string
    onConfirm: () => void
    onCancel?: () => void
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({ close, message, onConfirm, onCancel= () => {} }) => {
    const lang = useSelector ((store: StoreState) => store.lang)
    const [ opened, setOpened ] = useState (true)

    const pendingClose = () => {
        setOpened (false);
        setTimeout (() => close (), 500)
    }

    return <Dialog
        maxWidth={ 'xs' }
        fullWidth
        open={ opened }
        transitionDuration={ Settings.FADE_DURATION }
        onClose={ pendingClose }
    >
        <DialogContent>
            { message }
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                onCancel ()
                pendingClose ()
            }
            } color={ 'secondary' }>
                { lang.ui.commons.noBTN }
            </Button>
            <Button color={ 'primary' } onClick={ () => {
                onConfirm ()
                pendingClose ()
            } }>
                { lang.ui.commons.yesBTN }
            </Button>
        </DialogActions>
    </Dialog>
}